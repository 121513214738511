import React, { useCallback, useEffect, useRef, useState } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { conditionalExp } from "../utils/commonFn";
import axios from "axios";
import Loader from "../components/Loader";
import ErrorModal from "../components/ErrorModal";
import { SuccessModal } from "../components/SuccessModal";
import { CustomIcon } from "../components/Common";
import AddSkuModal from "../components/AddSkuModal";
import * as XLSX from 'xlsx'; 
function SkuManagement() {
  const customerId = JSON.parse(
    localStorage.getItem("mtm_user_data")
  )?.customer_id;

  const [addSKUDetails, setaddSKUDetails] = useState({
    customer_id: customerId,
    item_code: "",
    item_name: "",
    pallet_type: "",
    tax_code: "",
    unit_cost: "",
    boxes_per_pallet: "",
    package_factor: "",
    boxes_per_layer: "",
    pallet_length: "",
    pallet_width: "",
    pallet_height: "",
    c_length: "",
    c_width: "",
    c_height: "",
    item_weight: "",
    minimum_request_qty: "",
    stackable_pallet: "",
    no_of_stack: "",
    capacity: "",
    brand: "",
    brand_classification: "",
    vendor_code: "",
    supplier_country: "",
    item_country: "",
    group_name: "",
    units_per_box: "",
    units_per_pallet: "",
    purchase_status: "",
    sales_status: "",
    inactive_status: "",
    bar_code: "",
    alcohal_grad: "",
    incoterm: "",
    stock_qty: "",
  });
  const [addSKUDetailsError, setaddSKUDetailsError] = useState({
    item_code: null,
    item_name: null,
    pallet_type: null,
    tax_code: null,
    unit_cost: null,
    boxes_per_pallet: null,
    package_factor: null,
    boxes_per_layer: null,
    pallet_length: null,
    pallet_width: null,
    pallet_height: null,
    c_length: null,
    c_width: null,
    c_height: null,
    item_weight: null,
    minimum_request_qty: null,
    stackable_pallet: null,
    no_of_stack: null,
    capacity: null,
    brand: null,
    brand_classification: null,
    vendor_code: null,
    supplier_country: null,
    item_country: null,
    group_name: null,
    units_per_box: null,
    units_per_pallet: null,
    purchase_status: null,
    sales_status: null,
    inactive_status: null,
    bar_code: null,
    alcohal_grad: null,
    incoterm: null,
    stock_qty: null,
  });
  const [showSKUAdd, setShowSKUAdd] = useState(false);

  const [showUpload, setShowUpload] = useState(false);
  const [previewUrl, setpreviewUrl] = useState();
  const [isFileUploading, setisFileUploading] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [rawFile, setrawFile] = useState();
  const [rows, setRows] = useState([]);
  const [showErrModal, setShowErrModal] = useState(false);
  const [error, setError] = useState();
  const MAX_FILE_SIZE = 6 * 1024 * 1024; // 6MB in bytes
  const [fileUploadLimitRxError, setfileUploadLimitRxError] = useState(false);
  const [showSuccess, setshowSuccess] = useState(false);
  const [successMessagePopup, setSuccessMessagePopup] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, sethasMore] = useState(false);
  const observer = useRef();
  const tempRef = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      console.log("intersecting");
      if (isloading) {
        return;
      }
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setCurrentPage((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isloading, hasMore]
  );
  const handleShowUpload = () => {
    setpreviewUrl(null);
    setrawFile(null);
    setShowUpload(true);
  };

  const handleCloseUpload = () => {
    setShowUpload(false);
    setpreviewUrl(null);
    setrawFile(null);
  };
  const handleChangeFileFun = (e) => {
    for (let file of e.target.files) {
      if (file && file.size > MAX_FILE_SIZE) {
        setfileUploadLimitRxError(true);
        return;
      }
    }
    setfileUploadLimitRxError(false);
    setrawFile(Object.values(e.target.files));
  };
  const checkRxType = (file) => {
    let rawFilee = file ? file : rawFile;
    let objectURL = URL.createObjectURL(rawFilee);
    if (!rawFilee) {
      return (
        <img
          src={objectURL}
          style={{
            width: "40px",
            height: "30px",
            margin: "5px",
            marginRight: "10px",
          }}
          alt="doc"
        />
      );
    }
    let type = rawFilee?.name?.split(".");
    return getTypeDoc(objectURL, type);
  };
  const getTypeDoc = (path, typ) => {
    if (!path) {
      return "";
    }
    let type = typ ? typ : path?.split(".");
    if (type[type.length - 1] == "pdf") {
      return returnImgComp(window.location.origin + "/images/pdf.png");
    } else if (
      type[type.length - 1] == "xls" ||
      type[type.length - 1] == "xlsx"
    ) {
      return returnImgComp(window.location.origin + "/images/exel.png");
    } else if (
      type[type.length - 1] == "doc" ||
      type[type.length - 1] == "docx"
    ) {
      return returnImgComp(window.location.origin + "/images/doc.png");
    }

    return returnImgComp(path);

    function returnImgComp(src) {
      return (
        <img
          src={src}
          style={{
            width: "30px",
            height: "30px",
            margin: "5px",
            marginRight: "10px",
          }}
          alt="doc"
        />
      );
    }
  };
  function handleCloseSuccess() {
    setshowSuccess(false);
    setSuccessMessagePopup("");
  }
  async function handleFileUpload(event) {
    event.preventDefault();
    if (!rawFile?.length) {
      setError({ title: "Error", err: "Please select a file to upload" });
      setShowErrModal(true);
      return;
    }
    setisFileUploading(true);
    try {
      const token = localStorage.getItem("mtm_user_token");
      const formData = new FormData();
      formData.append(
        "customer_id",
        JSON.parse(localStorage.getItem("mtm_user_data"))?.customer_id
      );
      rawFile?.forEach((file) => {
        formData.append("document", file);
      });

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmcostcalculation/bulk/upload_items`,
        method: "POST",
        headers: {
          jwt_token: token,
        },

        data: formData,
      });

      if (!response?.data?.error) {
        setshowSuccess(true);
        setSuccessMessagePopup(response.data?.message);
        handleCloseUpload();
        // setErrRows(response?.data?.errorItems || []);
        setTimeout(() => {
          !response?.data?.errorItems?.length && handleCloseSuccess();
        }, 5000);
      } else {
        setError({ title: "Error", err: response?.data?.message });
        setShowErrModal(true);
      }
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });
      if (error?.response?.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setisFileUploading(false);
      getSkuList("reload");
    }
  }
  const navigate = useNavigate();
  const xlsxExport = (filename, tableId) => {
    const htmlTable = document.getElementById(tableId);

    if (htmlTable) {
        const ws = XLSX.utils.table_to_sheet(htmlTable);

        // Loop through each cell to apply the centering style
        for (const cell in ws) {
            if (ws.hasOwnProperty(cell)) {
                // Skip header cells (e.g., A1, B1, etc.)
                if (cell.match(/[A-Z]+1$/)) {
                    continue;
                }

                const cellRef = XLSX.utils.decode_cell(cell);
                const cellStyle = ws[cellRef.r] && ws[cellRef.r][cellRef.c]
                    ? Object.assign({}, ws[cellRef.r][cellRef.c], { alignment: { horizontal: 'center', vertical: 'center' } })
                    : { alignment: { horizontal: 'center', vertical: 'center' } };
                
                ws[cellRef.r] = ws[cellRef.r] || [];
                ws[cellRef.r][cellRef.c] = cellStyle;
            }
        }

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        // Generate the Excel file
        const excelBlob = new Blob([s2ab(XLSX.write(wb, { bookType: 'xlsx', type: 'binary' }))], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });

        // Create a downloadable link
        const url = URL.createObjectURL(excelBlob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${filename}.xlsx`;
        link.click();

        // Clean up the object URL
        URL.revokeObjectURL(url);
        // setModeexportbtn(false)
    }
};

// Convert string to ArrayBuffer
const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
        view[i] = s.charCodeAt(i) & 0xFF;
    }
    return buf;
};
  const handleCloseSKUAdd = () => {setShowSKUAdd(false);
  setaddSKUDetails({
    customer_id: customerId,
    item_code: "",
    item_name: "",
    pallet_type: "",
    tax_code: "",
    unit_cost: "",
    boxes_per_pallet: "",
    package_factor: "",
    boxes_per_layer: "",
    pallet_length: "",
    pallet_width: "",
    pallet_height: "",
    c_length: "",
    c_width: "",
    c_height: "",
    item_weight: "",
    minimum_request_qty: "",
    stackable_pallet: "",
    no_of_stack: "",
    capacity: "",
    brand: "",
    brand_classification: "",
    vendor_code: "",
    supplier_country: "",
    item_country: "",
    group_name: "",
    units_per_box: "",
    units_per_pallet: "",
    purchase_status: "",
    sales_status: "",
    inactive_status: "",
    bar_code: "",
    alcohal_grad: "",
    incoterm: "",
    stock_qty: "",
  })
  setaddSKUDetailsError({
    item_code: null,
    item_name: null,
    pallet_type: null,
    tax_code: null,
    unit_cost: null,
    boxes_per_pallet: null,
    package_factor: null,
    boxes_per_layer: null,
    pallet_length: null,
    pallet_width: null,
    pallet_height: null,
    c_length: null,
    c_width: null,
    c_height: null,
    item_weight: null,
    minimum_request_qty: null,
    stackable_pallet: null,
    no_of_stack: null,
    capacity: null,
    brand: null,
    brand_classification: null,
    vendor_code: null,
    supplier_country: null,
    item_country: null,
    group_name: null,
    units_per_box: null,
    units_per_pallet: null,
    purchase_status: null,
    sales_status: null,
    inactive_status: null,
    bar_code: null,
    alcohal_grad: null,
    incoterm: null,
    stock_qty: null,
  })
  };
  const handleShowSKUAdd = () => setShowSKUAdd(true);

  

  async function getSkuList(from) {
    try {
      setIsloading(true);
      let token = localStorage.getItem("mtm_user_token");
      if (from == "reload") {
        setCurrentPage(0);
      }
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmcostcalculation/customer/get_sku_items`,
        method: "POST",
        data: {
          customer_id: JSON.parse(localStorage.getItem("mtm_user_data"))
            ?.customer_id,
          page: from == "reload" ? 0 : currentPage,
          searchInput: searchInput,
        },
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
      });

      setIsloading(false);
      sethasMore(false);

      if (!response.data.error) {
        const newList = response.data.items.filter((newList) => {
          return !rows.some((existingList) => existingList.id === newList.id);
        });
        if (from == "reload") {
          setRows(response.data.items);
        } else {
          setRows([...rows, ...newList]);
        }
        sethasMore(newList.length > 0);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
      setCurrentPage(0);
    } finally {
      setIsloading(false);
    }
  }

  useEffect(() => {
    if (currentPage == 0) {
      getSkuList("reload");
    } else {
      getSkuList();
    }
  }, [currentPage]);

  useEffect(() => {
    if (!searchInput.length) {
      getSkuList("reload");
    }
  }, [searchInput]);

  useEffect(() => {
    if (!rawFile || rawFile.length == 0) {
      setpreviewUrl(null);
      return;
    }
    const objectURL = URL.createObjectURL(
      rawFile.length ? rawFile[0] : rawFile
    );
    setpreviewUrl(objectURL);
  }, [rawFile]);

  return (
    <>
      <Container style={{ minWidth: "100%" }}>
        <Row>
          <Col>
            <div className="wrapper-box">
              <h2 className="fw-700 fs-30 text-center">SKU Management</h2>

              <div className="d-flex justify-content-between align-items-end">
                <div className="d-flex ">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      getSkuList("reload");
                    }}
                    className="d-flex mt-2"
                  >
                    <input
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                      type="search"
                      className="form-control rounded-pill  d-block"
                      style={{ width: "400px", alignSelf: "start" }}
                      placeholder="Search Item Code or Name"
                      id="datatable-search-input"
                    />
                    <button
                      type="submit"
                      className="grd-btn mx-2"
                      disabled={!searchInput}
                    >
                      <CustomIcon name="icon_search" className="fs-5" />
                    </button>
                  </form>
                </div>

                <div className="me-4 d-flex">
                  <button
                    onClick={() => {
                    xlsxExport('sku_list','skuListTable')
                    }}
                    type="button"
                    className="grd-btn rounded-pill py-1 me-4"
                  >
                    Download Exel
                  </button>
                  <button
                    onClick={() => {
                      setaddSKUDetails({
                        customer_id: customerId,
                        item_code: "",
                        item_name: "",
                        pallet_type: "",
                        tax_code: "",
                        unit_cost: "",
                        boxes_per_pallet: "",
                        package_factor: "",
                        boxes_per_layer: "",
                        pallet_length: "",
                        pallet_width: "",
                        pallet_height: "",
                        c_length: "",
                        c_width: "",
                        c_height: "",
                        item_weight: "",
                        minimum_request_qty: "",
                        stackable_pallet: "",
                        no_of_stack: "",
                        capacity: "",
                        brand: "",
                        brand_classification: "",
                        vendor_code: "",
                        supplier_country: "",
                        item_country: "",
                        group_name: "",
                        units_per_box: "",
                        units_per_pallet: "",
                        purchase_status: "",
                        sales_status: "",
                        inactive_status: "",
                        bar_code: "",
                        alcohal_grad: "",
                        incoterm: "",
                        stock_qty: "",
                      });
                      handleShowSKUAdd();
                    }}
                    type="button"
                    className="grd-btn rounded-pill py-1 me-4"
                  >
                    Add SKU
                  </button>
                  <button
                    onClick={handleShowUpload}
                    type="button"
                    className="grd-btn rounded-pill py-1"
                  >
                    Bulk Upload SKU
                  </button>
                </div>
              </div>
            </div>
            <div
              style={{
                overflowX: "auto",
              }}
              className=" normal-tbl"
            >
              <table id="skuListTable" class="text-center my-4">
                <thead>
                  <tr>
                    <th></th>
                    <th>Item Code</th>
                    {/* <th>Item Group Code</th> */}
                    <th>Item Name</th>
                    <th>Minimum Request Qty</th>
                    <th>Pallet Type</th>
                    <th>Pallet Height (mm)</th>
                    <th>Pallet Length (mm)</th>
                    <th>Pallet Width (mm)</th>
                    <th>Stackable Pallet</th>
                    <th>No Of Stack</th>
                    <th>Tax Code</th>
                    <th>Capacity</th>
                    <th>Brand</th>
                    <th>Brand Classification</th>
                    <th>Vendor Code</th>
                    <th>Supplier Country</th>
                    <th>Item Country</th>
                    <th>Group Name</th>
                    <th>Boxes Per Layer</th>
                    <th>Boxes Per Pallet</th>
                    <th>Box Height (mm)</th>
                    <th>Box length (mm)</th>
                    <th>Box width (mm)</th>
                    <th>Units Per Box</th>
                    <th>Units Per Pallet</th>
                    <th>Unit Cost (USD)</th>
                    {/* <th>Origin Currency</th> */}
                    <th>Purchase Status</th>
                    <th>Sales Status</th>
                    <th>Inactive Status</th>
                    <th>Barcode</th>
                    <th>Alcohol %</th>
                    <th>Incoterm</th>
                    <th>Stock Qty</th>
                    <th>Unit Weight(grams)</th>
                    <th>Package Factor</th>
                  </tr>
                </thead>
                <tbody>
                  {rows?.map((row, index) => (
                    <tr
                      ref={
                        rows?.length == index + 1 ? lastBookElementRef : tempRef
                      }
                      key={row?.item_code + "itrems" + index}
                      style={{ overflow: "visible" }}
                    >
                      <td>
                        <button
                          onClick={() => {
                            setaddSKUDetails({
                              customer_id: customerId,
                              ...row,
                            });
                            handleShowSKUAdd();
                          }}
                          type="button"
                          className="edit-btn"
                        >
                          <svg className="icon">
                            <use href="#icon_edit"></use>
                          </svg>
                        </button>
                      </td>
                      <td>{row.item_code}</td>
                      {/* <td>{row.items_group_code}</td> */}
                      <td>{row.item_name}</td>
                      <td>{row.minimum_request_qty}</td>
                      <td>{row.pallet_type}</td>
                      <td>{row.pallet_height}</td>
                      <td>{row.pallet_length}</td>
                      <td>{row.pallet_width}</td>
                      <td>{row.stackable_pallet}</td>
                      <td>{row.no_of_stack}</td>
                      <td>{row.tax_code}</td>
                      <td>{row.capacity}</td>
                      <td>{row.brand}</td>
                      <td>{row.brand_classification}</td>
                      <td>{row.vendor_code}</td>
                      <td>{row.supplier_country}</td>
                      <td>{row.item_country}</td>
                      <td>{row.group_name}</td>
                      <td>{row.boxes_per_layer}</td>
                      <td>{row.boxes_per_pallet}</td>
                      <td>{row.c_height}</td>
                      <td>{row.c_length}</td>
                      <td>{row.c_width}</td>
                      <td>{row.units_per_box}</td>
                      <td>{row.units_per_pallet}</td>
                      <td>{row.unit_cost}</td>
                      {/* <td>{row.origin_currency}</td> */}
                      <td>{row.purchase_status}</td>
                      <td>{row.sales_status}</td>
                      <td>{row.inactive_status}</td>
                      <td>{row.bar_code}</td>
                      <td>{row.alcohal_grad}</td>
                      <td>{row.incoterm}</td>
                      <td>{row.stock_qty}</td>
                      <td>{row.item_weight}</td>
                      <td>{row.package_factor}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {conditionalExp(
                isloading == false && rows.length == 0,
                <p className="w-full  text-center">No SKU found</p>
              )}
              {conditionalExp(
                isloading,
                <div className="w-full text-center">
                  <Loader />
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      <Modal
        show={showUpload}
        centered
        onHide={handleCloseUpload}
        className="tracking-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5">
          <h4 className="text-center pb-3 fs-22">Bulk Upload SKU</h4>
          <p className="text-center pb-3">
            <b>Note:</b> Only excel files are accepted. <br />
            <a
              className="text-center mb-3 "
              href={`SKU Import Format.xlsx`}
              download
            >
              Download Sample File
            </a>
          </p>

          <div className="upload-box p-3">
            <label className="drag-box input-file">
              <img
                className="img-fluid rounded-start"
                width={80}
                height={55}
                src={window.location.origin + "/images/uploader.png"}
                alt="..."
              />
              <input
                type="file"
                accept=".xls,.xlsx"
                id="fileUpload2"
                onChange={handleChangeFileFun}
              />
              <span>
                Drag and drop your image or <a href="">browse file</a> on your
                computer
              </span>
            </label>
          </div>
          <div className="d-block rounded-2 p-1 my-3">
            {previewUrl ? (
              rawFile?.map((file, index) => (
                <div
                  key={file?.name + "rawFiles"}
                  className="file-import d-flex ai-center my-2"
                  id="remove_${inputid}"
                >
                  {checkRxType(file)}
                  <span style={{ marginRight: "auto" }}>{file?.name}</span>

                  <button
                    onClick={() => {
                      setrawFile((pre) =>
                        pre.filter((item, iIndex) => index != iIndex)
                      );
                    }}
                  >
                    <svg
                      className="icon"
                      style={{ width: "20px", height: "20px" }}
                    >
                      <use href="#icon_fileclose"></use>
                    </svg>
                  </button>
                </div>
              ))
            ) : (
              <div></div>
            )}
          </div>
          {fileUploadLimitRxError && (
            <div className="mb-2 w-100 text-danger text-center">
              Please upload file less than 6MB
            </div>
          )}
          <div className="mt-1">
            <button
              disabled={isFileUploading}
              onClick={handleFileUpload}
              className="button button-blue upload-btn w-100 py-2 fs-20"
            >
              {conditionalExp(isFileUploading, <Loader />, "Upload")}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <ErrorModal
        error={error}
        setShowErrModal={setShowErrModal}
        showErrModal={showErrModal}
      />
      <SuccessModal
        showSuccess={showSuccess}
        successMessagePopup={successMessagePopup}
        handleCloseSuccess={handleCloseSuccess}
      />
      <AddSkuModal
        addSKUDetails={addSKUDetails}
        addSKUDetailsError={addSKUDetailsError}
        setaddSKUDetails={setaddSKUDetails}
        setaddSKUDetailsError={setaddSKUDetailsError}
        customerId={customerId}
        getSKUList={() => getSkuList("reload")}
        handleCloseSKUAdd={handleCloseSKUAdd}
        showSKUAdd={showSKUAdd}
      />
    </>
  );
}

export default SkuManagement;
