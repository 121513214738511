import axios from "axios";
import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ErrorModal from "../components/ErrorModal";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import { SuccessModal } from "../components/SuccessModal";
function ForcastPeriod() {
  const mtm_user_data = JSON.parse(localStorage.getItem("mtm_user_data"));
  const [formdata, setFormData] = useState({
    customer_id: mtm_user_data?.customer_id,
    customer_user_id: mtm_user_data?.customer_user_id,
    demand_forcast_type:mtm_user_data?.demand_forcast_type || '',
    });
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [error, setError] = useState();
  const [showErrModal, setShowErrModal] = useState(false);
  const navigate = useNavigate();
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [sucessMsg, setSucessMsg] = useState("")
    const [demandforcasttypes, setDemandforcasttypes] = useState([])

  const handleFormData = (e) => {
    setFormData({
      ...formdata,
      [e.target.id]: e.target.value,
    });
  };

  async function updatePassFn() {
    setIsLoadingSubmit(true);
    try {
     
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmcustomer/profile/update_demand_forcast_type`,
        method: "POST",
        headers: {
          jwt_token: localStorage.getItem("mtm_user_token"),
        },
        data: formdata,
      });
      if (response.status == 200 && !response.data.error) {
        localStorage.setItem("mtm_user_data", JSON.stringify(response.data.user));
        setSucessMsg("Forecast Period updated successfully")
        setShowSuccessModal(true)
        setTimeout(() => {
          setSucessMsg('')
          setShowSuccessModal(false)
        }, 2000);

      } else {
        setError({ title: "Error", err: response.data.message });
        setShowErrModal(true);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    } finally {
      setIsLoadingSubmit(false);
    }
  }
  async function getDemandForcastType() {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmdemandforcast/demandforcasttypes`,
        method: "GET",
        headers: {
          jwt_token: localStorage.getItem("mtm_user_token"),
        },
      });
      if (response.status == 200 && !response.data.error) {
       setDemandforcasttypes(response.data?.result?.map((item) => {
          return {
            value: item?.type_name,
            label: item?.type_name,
          };
        }) || [])

      } else {
        setError({ title: "Error", err: response.data.message });
        setShowErrModal(true);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    } finally {
      setIsLoadingSubmit(false);
    }
  }

  useEffect(() => {
    getDemandForcastType()
  }, [])
  

  return (
    <>
      <Container fluid>
        <Row>
          <Col sm={12}>
            <h2 className="fw-700 fs-30 py-4">Forecast Period</h2>
          </Col>
        </Row>
        <div className="row">
          <div className="col-sm-12 col-md-10 col-lg-8">
            <div className="row pb-4 g-4">
                  <div className="col-sm-12">
                    <label className="form-label fw-500 fs-18">
                      Select Demand Forecast Period
                    </label>
                    <select value={formdata.demand_forcast_type} onChange={handleFormData} className="form-select" id="demand_forcast_type">
                      <option value="">Select</option>
                      {demandforcasttypes?.map((item, index) => {
                        return (
                          <option key={index} value={item?.value}>
                            {item?.label?.toUpperCase()}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-12">
                    <button
                      disabled={isLoadingSubmit || mtm_user_data?.demand_forcast_type == formdata.demand_forcast_type }
                      type="button"
                      className="grd-btn rounded-pill py-1"
                      onClick={updatePassFn}
                    >
                      {isLoadingSubmit ? <Loader /> : "Update"}
                    </button>
                  </div>
               
            </div>
          </div>
        </div>
      </Container>
      <ErrorModal
        error={error}
        setShowErrModal={setShowErrModal}
        showErrModal={showErrModal}
      />
    <SuccessModal handleCloseSuccess={()=>setShowSuccessModal(false)} showSuccess={showSuccessModal} successMessagePopup={sucessMsg}/>

    </>
  );
}

export default ForcastPeriod;
