import React, { useContext } from "react";
import { Link, NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { ContainerContext } from "../context/containerContext";

function AsideBar2() {
  const { containers, selectedContainer, updateSelectedContainer } =
    useContext(ContainerContext);
  const moreLink = (env, id) => {
    if (!env.target.matches(".active")) {
      document.querySelectorAll(".nav-toggle").forEach((e) => {
        e.classList.remove("active");
      });
      document.querySelectorAll(".navmore-link").forEach((e) => {
        e.classList.remove("active");
      });
      env.target.classList.add("active");
      document.getElementById(id).classList.add("active");
    } else {
      env.target.classList.remove("active");
      document.getElementById(id).classList.remove("active");
    }
  };
  const location = useLocation();
  const { shipment_id } = useParams();
const navigate = useNavigate()

  return (
    <>
      <div className="aside-new" id="asideBar">
        {location.pathname == "/company-settings" ? (
          <ul>
            {/* <li>
                <NavLink className="nav-link" to="/">
                    <span>
                        <svg className="icon">
                            <use href="#icon_truck"></use>
                        </svg>
                    </span>
                    <strong>Home</strong>
                </NavLink>
            </li> */}
            <li>
              <div
                className="nav-link nav-toggle"
                onClick={(e) => moreLink(e, "more1")}
              >
                <span>
                  <svg className="icon">
                    <use href="#icon_connect"></use>
                  </svg>
                </span>
                <strong>Connects</strong>
              </div>
              <div className="navmore-link" id="more1">
                <NavLink to="/">ERPS</NavLink>
                <NavLink to="/">ABC</NavLink>
                <NavLink to="/">XYZ</NavLink>
              </div>
            </li>
            <li>
              <div
                className="nav-link nav-toggle"
                onClick={(e) => moreLink(e, "more2")}
              >
                <span>
                  <svg className="icon">
                    <use href="#icon_subscription"></use>
                  </svg>
                </span>
                <strong>Subscription</strong>
              </div>
              <div className="navmore-link" id="more2">
                <NavLink to="/">ERPS</NavLink>
                <NavLink to="/">ABC</NavLink>
                <NavLink to="/">XYZ</NavLink>
              </div>
            </li>
            <li>
              <div
                className="nav-link nav-toggle"
                onClick={(e) => moreLink(e, "more3")}
              >
                <span>
                  <svg className="icon">
                    <use href="#icon_user"></use>
                  </svg>
                </span>
                <strong>User</strong>
              </div>
              <div className="navmore-link" id="more3">
                <NavLink to="/">One</NavLink>
                <NavLink to="/">Two</NavLink>
                <NavLink to="/">Three</NavLink>
              </div>
            </li>
          </ul>
        ) : (
          ""
        )}
        {location.pathname == `/tracking/booking_number/${shipment_id}` ? (
          <div>
            <div className="w-100 py-3" style={{ paddingLeft: "60px" }}>
              <div onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
                <button type="button" className="back-btn">
                  <svg className="icon  fs-22">
                    <use href="#icon_leftarrow"></use>
                  </svg>
                <strong className="fs-14">Back</strong>
                </button>
              </div>
              <div className="plus-btn pb-3 pt-5">
              <strong style={{fontWeight:'bolder',fontSize:'20px'}} >Containers</strong>
              </div>
            </div>
            <ul className="pan-btn">
              {containers?.map((container, index) => (
                <li key={container?.container_id + "container"}>
                  <button
                    type="button"
                    onClick={() => updateSelectedContainer(container)}
                    style={{
                      background:
                        selectedContainer.container_id ==
                        container?.container_id
                          ? "#EEF1FF"
                          : "",
                    }}
                  >
                    {container?.container_id}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default AsideBar2;
