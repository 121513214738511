import React, { useContext, useEffect, useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { CustomerContext } from '../context/customerContext';
import Loader from './Loader';
import axios from 'axios';

function NavBar() {
  const navigate = useNavigate()
  const [gettingCustomer, setGettingCustomer] = useState(false)
  const { customer, updateCustomer} = useContext(CustomerContext)
  
  const getCustomer = async () => {
    setGettingCustomer(true);
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmcustomer/getSingle`,
        method: "POST",
        headers: {
          jwt_token: localStorage.getItem("mtm_user_token"),
        },
        data: {
          customer_id: JSON.parse(localStorage.getItem("mtm_user_data"))
            ?.customer_id,
        },
      });
      if (response.status == 200) {
        setGettingCustomer(true);
        if (!response.data.error) {
          updateCustomer(
            response?.data?.customer
          );
        }else{
            updateCustomer(null)
        }
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    } finally {
      setGettingCustomer(false);
    }
  };
  const logOut = async () => {
    setGettingCustomer(true);
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmuser/logout`,
        method: "POST",
        headers: {
          jwt_token: localStorage.getItem("mtm_user_token"),
        },
        data: {
          email: JSON.parse(localStorage.getItem("mtm_user_data"))
            ?.user_email,
        },
      });
      
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    } finally {
      setGettingCustomer(false);
      localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
    }
  };

  const mtmUserData = JSON.parse(localStorage.getItem("mtm_user_data"));

  useEffect(() => {
   
   getCustomer()
  }, [])
  
  return (
    <>
        <Navbar expand="lg">
            <Container>
                <Navbar.Brand onClick={()=>navigate('/')} href="#" className='d-flex'>
                    <img src={window.location.origin +'/images/logo.png'} style={{ width: "140px", height: "40px", maxHeight: "52px" }}></img>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                    <Form className="d-flex ms-auto me-5">
                        {/* <button type='button' className='logout-btn'>Logout</button> */}
                        {gettingCustomer? <Loader/>:<div className="btn-group me-5 dropdown-center">
                            <button type="button" className="userdrop dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                {/* <img style={{width: "40px"}} src={window.location.origin +'/images/user.png'}/> */}
                                <svg className="icon rounded-5 fs-35 " style={{color:'#1a31b2'}} >
                                            <use href="#icon_user_filled"></use>
                                        </svg>
                            </button>
                            <ul className="dropdown-menu dropdown-menu-lg-end p-2 py-3">
                                <li className='d-flex align-items-center py-2'>
                                    <div className='img-box'>
                                    <svg className="icon rounded-5 fs-35 " style={{color:'#1a31b2'}} >
                                            <use href="#icon_user_filled"></use>
                                        </svg>
                                    </div>
                                    <div className='text-box '>
                                        <p><strong>Name:</strong> {mtmUserData?.user_firstname + " " + mtmUserData?.user_lastname}</p>
                                        <p><strong>Email id:</strong> {mtmUserData?.user_email}</p>
                                    </div>
                                </li>
                                <hr className="dropdown-divider"/>
                                <li><strong>Customer Code: {customer?.customer_id || mtmUserData?.customer_id}</strong></li>
                                <hr className="dropdown-divider"/>
                                <li>
                                    <button onClick={()=>navigate('/profile-settings')} type='button' className='drop-btn'>
                                        <svg className="icon fs-22 me-2">
                                            <use href="#icon_profile"></use>
                                        </svg>
                                        Profile Settings
                                        <svg className="icon fs-18 position-absolute arrow">
                                            <use href="#icon_rarrow"></use>
                                        </svg>
                                    </button>
                                </li>
                                <hr className="dropdown-divider"/>
                                {/* <li>
                                    <button onClick={()=>navigate('/company-settings')} type='button' className='drop-btn'>
                                        <svg className="icon fs-20 me-2">
                                            <use href="#icon_setting"></use>
                                        </svg>
                                        Settings
                                        <svg className="icon fs-18 position-absolute arrow">
                                            <use href="#icon_rarrow"></use>
                                        </svg>
                                    </button>
                                </li> */}
                                {/* <hr className="dropdown-divider"/> */}
                                <li>
                                    <button onClick={()=>logOut()} type='button' className='drop-btn'>
                                        <svg className="icon fs-22 me-2">
                                            <use href="#icon_logout"></use>
                                        </svg>
                                        Logout
                                        <svg className="icon fs-18 position-absolute arrow">
                                            <use href="#icon_rarrow"></use>
                                        </svg>
                                    </button>
                                </li>
                            </ul>
                        </div>}
                    </Form>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    </>
  )
}

export default NavBar