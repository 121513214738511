import moment from 'moment';
import React from 'react';
import { getStatusIcon } from '../utils/commonFn';

export const InfoDiv = ({ marker,close }) => {
  return (
    <div  className="status-card">
              <ul>
                <li>
                  <div className='d-flex align-items-center'>
                    <strong>Status:</strong>
                   
                    {getStatusIcon(marker?.status_name,'s')}
                  </div>
                </li>
                <li>
                  <strong>Booking Id:&nbsp;</strong>
                  <span>{marker?.booking_id}</span>
                </li>
                <li>
                  <strong>ETA:&nbsp;</strong>
                  <span>{marker?.expected_date && moment(marker?.expected_date).format('DD/MM/YYYY')}</span>
                </li>
                <li>
                  <strong>POL:&nbsp;</strong>
                  <span>{marker?.port_of_loading}</span>
                </li>
                <li>
                  <strong>POD:&nbsp;</strong>
                  <span>{marker?.port_of_discharge}</span>
                </li>
                <li>
                  <strong>Shipping Line:&nbsp;</strong>
                  <span>{marker?.career_scac}</span>
                </li>
                <li>
                  <strong>Number Of Containers:&nbsp;</strong>
                  <span>{marker?.total_container}</span>
                </li>
              </ul>
            </div>
  );
};
