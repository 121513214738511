import ListGroup from "react-bootstrap/ListGroup";
import {
  Container,
  Row,
  Col,
  Tab,
  Tabs,
  Form,
  Modal,
  Button,
} from "react-bootstrap";
import "react-quill/dist/quill.snow.css";
import ApexChart from "../components/ApexChart";
import TypeChart from "../components/TypeChart";
import CountryChart from "../components/CountryChart";
import ShippingChart from "../components/ShippingChart";
import GMap from "../components/GMap";
import WebSocket from "isomorphic-ws";
import {
  conditionalExp,
  getDashboardData,
  getFormattedDate,
} from "../utils/commonFn";
import { DatePicker } from "antd";
import Select from "react-select";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import ErrorModal from "../components/ErrorModal";
import { DateRangePicker } from "react-date-range";
import { CustomIcon } from "../components/Common";
import axios from "axios";
// import TypeChart from './components/TypeChart';
const { RangePicker } = DatePicker;
function Dashboard() {
  const [initialdate, setInitialdate] = useState(true);
  const [startDate, setStartDate] = useState(
    moment().subtract(1, "year").toDate()
  );
  const [endDate, setEndDate] = useState(moment().toDate());
  const [showdatepicker, setShowdatepicker] = useState(false);
  const [show, setShow] = useState(false);
  const [showicon, setShowicon] = useState(false);
  const [isLoadingChat, setIsLoadingChat] = useState(false);
  const [chatQuery, setChatQuery] = useState("");
  const [chatData, setChatData] = useState([]);
  const [chat_lang, setChat_lang] = useState("");
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem("mtm_user_data") || "{}")
  );
  const [selectedDate, setSelectedDate] = useState(
    [
      dayjs(moment().subtract(1, "year")), // Set the "from" date 1 week before today
      dayjs(moment()), // Set the "to" date as today
    ].map((item) => {
      return getFormattedDate(item);
    })
  );
  const [dashboardRawData, setDashboardRawData] = useState();
  const [dashboardRawDataMap, setDashboardRawDataMap] = useState();
  const [statusPieList, setStatusPieList] = useState([]);
  const [containerTypeList, setContainerTypeList] = useState([]);
  const [countryContainerList, setCountryContainerList] = useState([]);
  const [shippingLineContainerList, setShippingLineContainerList] = useState(
    []
  );
  const [selectedContainerType, setSelectedContainerType] = useState();
  const [statuses, setStatuses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [showErrModal, setShowErrModal] = useState(false);
  const [ws, setWs] = useState(null);
  const chatLoadingRef = useRef();
  const dateFormat = "DD/MM/YYYY";
  const navigate = useNavigate();
  const handleDateChange = (dates) => {
    setSelectedContainerType();
    if (!dates) {
      setSelectedDate(
        [
          dayjs(moment().subtract(1, "week")), // Set the "from" date 1 week before today
          dayjs(moment()), // Set the "to" date as today
        ].map((item) => {
          return getFormattedDate(item);
        })
      );
    } else {
      setSelectedDate(
        dates?.map((item) => {
          return getFormattedDate(item);
        })
      );
    }
  };

  // async function handleChatSend(e) {
  //   e.preventDefault();
  //   setChatQuery("");

  //   let newChatData1 = [...chatData];
  //   newChatData1.push({
  //     type: "receiver",
  //     message: chatQuery,
  //     timestamp: new Date(),
  //   });
  //   setChatData(newChatData1);
  //   setIsLoadingChat(true);
  //   const chatContainer = chatLoadingRef.current;

  //   // Scroll to the bottom
  //   chatContainer.scrollTop = chatContainer.scrollHeight + 50;
  //   try {
  //     // console.log('got into try');
  //     const chatFormData = new FormData();
  //     chatFormData.append("query", chatQuery);
  //     chatFormData.append("customer_id", userData?.customer_id);
  //     chatFormData.append("model", "gpt-3.5-turbo-16k");
  //     // chatFormData.append("language", userData?.default_language?.toLowerCase() );
  //     chatFormData.append("language", "english");
  //     let response = await axios({
  //       url: `${process.env.REACT_APP_CHAT_BASE_URL}/chat_database/`,
  //       method: "POST",
  //       headers: {
  //         // jwt_token: localStorage.getItem("mtm_user_token"),
  //       },
  //       // data: chatFormData,
  //       data: {
  //         customer_id: userData?.customer_id,
  //         query: chatQuery,
  //         model: "gpt-3.5-turbo-16k",
  //         language: "english",
  //       },
  //     });
  //     if (response.status == 200) {
  //       setIsLoadingChat(true);
  //       newChatData1.push({
  //         type: "sender",
  //         message: response?.data?.output || " ",
  //         timestamp: new Date(),
  //       });
  //       setChatData(newChatData1);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     newChatData1.push({
  //       type: "error",
  //       message: "Apologies, an unexpected error occurred from the backend.",
  //       timestamp: new Date(),
  //     });
  //     setChatData(newChatData1);
  //     if (error?.response?.status == 401) {
  //       localStorage.removeItem("mtm_user_token");
  //       localStorage.removeItem("mtm_user_data");
  //       navigate("/login");
  //       return;
  //     }
  //   } finally {
  //     const chatContainer = chatLoadingRef.current;

  //     // Scroll to the bottom
  //     chatContainer.scrollTop = chatContainer.scrollHeight + 50;
  //     setIsLoadingChat(false);
  //   }
  // }

  async function handleChatSend(e) {
    e.preventDefault();
    setChatQuery("");

    let newChatData1 =chatData;
    newChatData1.push({
      type: "receiver",
      message: chatQuery,
      timestamp: new Date(),
    });
    setChatData(newChatData1);
    setIsLoadingChat(true);
    const chatContainer = chatLoadingRef.current;

    // Scroll to the bottom
    chatContainer.scrollTop = chatContainer.scrollHeight + 100;
    let newWs = ws;
    if (newWs?.readyState == newWs?.CLOSED ) {
      // ws.close(); // Close the existing WebSocket connection if it exists
      // setWs(null); // Reset the WebSocket state
      console.log('websocket is closed condition');
      newWs = new WebSocket(
        `${process.env.REACT_APP_CHAT_BASE_URL}/chat_database`
        )
        setWs(newWs);
      }
      
      
      
      
      
      newWs.onopen = function open() {
        console.log("websocket opened");
        let new_data = {
          customer_id: userData?.customer_id,
          query: chatQuery,
          // model: "gpt-3.5-turbo-16k",
          language: "English",
        };
        const dataToSend = JSON.stringify(new_data);
        newWs.send(dataToSend);
        console.log('sent to websocket on open',dataToSend);
      };
      
      console.log(newWs.readyState == newWs.OPEN);
      if(newWs?.readyState == newWs?.OPEN){
        let new_data = {
          customer_id: userData?.customer_id,
          query: chatQuery,
          // model: "gpt-3.5-turbo-16k",
          language: "English",
        };
        const dataToSend = JSON.stringify(new_data);
        newWs.send(dataToSend);
        console.log('sent to websocket',dataToSend);
      }

    newWs.onmessage = function incoming(data) {
      console.log("received", data.data);

      if (data?.data) {
        let response = JSON.parse(data.data);
        if (response?.error) {
          newChatData1.push({
            type: "error",
            message: response?.message,
            timestamp: new Date(),
          });
        } else {
          newChatData1.push({
            type: "sender",
            message: response?.output ,
            timestamp: new Date(),
          });
        }
        
      } else {
        newChatData1.push({
          type: "error",
          message: "Apologies, an unexpected error occurred from the backend.",
          timestamp: new Date(),
        });
     
      }
      setChatData(newChatData1);
    setIsLoadingChat(false);
    // Scroll to the bottom
    chatContainer.scrollTop = chatContainer.scrollHeight + 100;
    };

    newWs.onclose = () => {
      console.log("WebSocket connection closed.");
      setChatData([])
    };
    newWs.onerror = (error) => {
      newChatData1.push({
        type: "error",
        message:
          // "Apologies, an unexpected error occurred from the backend./n " +
          error,
        timestamp: new Date(),
      });
      setChatData(newChatData1);
    setIsLoadingChat(false);
    // Scroll to the bottom
    chatContainer.scrollTop = chatContainer.scrollHeight + 100;
      console.error("WebSocket error:", error);
    };

  
    
  }

  async function handleLanguageUpdate() {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmcustomer/profile/change_language`,
        method: "POST",
        headers: {
          jwt_token: localStorage.getItem("mtm_user_token"),
        },
        data: {
          customer_user_id: userData?.customer_user_id,
          default_language: chat_lang,
        },
      });
      if (response.status == 200 && !response.data.error) {
        localStorage.setItem(
          "mtm_user_data",
          JSON.stringify(response.data.user)
        );
        setUserData({ ...userData, default_language: chat_lang });
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    }
  }

  //chat ui functions
  const handleClose = () => {
    document.querySelector("body").classList.remove("chat-active");
    setShow(false);
    setShowicon(false);
    if(ws?.readyState == ws?.OPEN){

      ws?.close()
    }
  };
  const handleShow = () => setShow(true);
  const fullSize = () => {
    if (showicon) {
      setShowicon(false);
    } else {
      setShowicon(true);
    }
    document.querySelector("body").classList.toggle("chat-active");
  };

  

  useEffect(() => {
    if (chat_lang) {
      handleLanguageUpdate();
    }

    // if (chat_lang == "Spanish" && !chatData.length) {
    //   setChatData([
    //     {
    //       type: "sender",
    //       message: "Hola, ¿cómo puedo ayudarte?",
    //       timestamp: new Date(),
    //     },
    //   ]);
    // } else if (!chatData.length) {
    //   setChatData([
    //     {
    //       type: "sender",
    //       message: "Hello, how can I help you?",
    //       timestamp: new Date(),
    //     },
    //   ]);
    // }
  }, [chat_lang]);

  useEffect(() => {
    // console.log(selectedDate, "selectedDate");
    
    if (userData?.default_language) {
      setChat_lang(userData?.default_language);
    }
    getDashboardData(
      [
        moment(startDate).format("YYYY-MM-DD"),
        moment(endDate).format("YYYY-MM-DD"),
      ],
      navigate,
      {
        setDashboardRawData,
        setDashboardRawDataMap,
        setStatusPieList,
        setIsLoading,
        setShowErrModal,
        setError,
        setStatuses,
        setContainerTypeList,
        setCountryContainerList,
        setShippingLineContainerList,
      }
    );
    return () => {
      if (ws) {
        ws?.close();
      }
    };
  }, []);

  return (
    <>
      <Container fluid>
        <Row>
          <Col className="col-12 py-3 d-flex justify-content-end">
            <div className="me-4">
              <Select
                value={selectedContainerType}
                onChange={(option) => {
                  setSelectedContainerType(option);
                  getDashboardData(
                    [
                      moment(startDate).format("YYYY-MM-DD"),
                      moment(endDate).format("YYYY-MM-DD"),
                    ],
                    navigate,
                    {
                      setDashboardRawData,
                      setDashboardRawDataMap,
                      setStatusPieList,
                      setIsLoading,
                      setShowErrModal,
                      setError,
                      setStatuses,
                      setContainerTypeList,
                      setCountryContainerList,
                      setShippingLineContainerList,
                    },
                    option
                  );
                }}
                placeholder="Container Type"
                aria-label="Container Type"
                isClearable={true}
                options={containerTypeList?.map((item) => ({
                  label: item?.container_type,
                  value: item?.container_type,
                }))}
              />
            </div>
            {/* <div>
                <RangePicker
                  onChange={(values) => handleDateChange(values)}
                  style={{ height: "38px" }}
                  format={dateFormat}
                  picker="date"
                  value={[dayjs(selectedDate[0]), dayjs(selectedDate[1])]}
                />
                <DateRangePicker
                    ranges={[
                      {
                        startDate,
                        endDate,
                        key: "selection",
                        color: "#1a31b2",
                        rangeColors: "#1a31b2",
                      },
                    ]}
                    onChange={(e) => {
                      setInitialdate(false);
                      setStartDate(e?.selection?.startDate);
                      setEndDate(e?.selection?.endDate);
                    }}
                  />
              </div> */}
            <div className="mb-20 mt-20 w-45">
              <input
                className="input-box input-date gray"
                name="from_date"
                placeholder={
                  !initialdate
                    ? `${moment(startDate).format(dateFormat)}-${moment(
                        endDate
                      ).format(dateFormat)}`
                    : "Select Date Range"
                }
                onClick={() => {
                  showdatepicker
                    ? setShowdatepicker(false)
                    : setShowdatepicker(true);
                }}
                readOnly
              />
              {showdatepicker && (
                <div className="date-picker-body">
                  <div className="form-card" style={{ paddingTop: "20px" }}>
                    <DateRangePicker
                      ranges={[
                        {
                          startDate,
                          endDate,
                          key: "selection",
                          color: "#0E2181",
                          rangeColors: "#303f8a",
                        },
                      ]}
                      onChange={(e) => {
                        setInitialdate(false);
                        setStartDate(e?.selection?.startDate);
                        setEndDate(e?.selection?.endDate);
                      }}
                    />
                    <div>
                      <button
                        style={{ marginLeft: "10px" }}
                        className="button   "
                        type="button"
                        onClick={() => setShowdatepicker(false)}
                      >
                        Cancel
                      </button>
                      <button
                        style={{ marginLeft: "30px" }}
                        className="button normal-btn"
                        onClick={() => {
                          setShowdatepicker(false);
                          getDashboardData(
                            [
                              moment(startDate).format("YYYY-MM-DD"),
                              moment(endDate).format("YYYY-MM-DD"),
                            ],
                            navigate,
                            {
                              setDashboardRawData,
                              setDashboardRawDataMap,
                              setStatusPieList,
                              setIsLoading,
                              setShowErrModal,
                              setError,
                              setStatuses,
                              setContainerTypeList,
                              setCountryContainerList,
                              setShippingLineContainerList,
                            }
                          );
                        }}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Col>
        </Row>
        <div className="row row-cols-2 row-cols-md-3 row-cols-lg-3 g-3 pb-4">
          <div className="col " style={{ height: "max-content" }}>
            <div className={`dash-card ${isLoading ? "skeleton" : ""}`}>
              <div className="line">
                <div className="d-flex align-items-center">
                  <div className="icon-box me-2">
                    <svg className="icon fs-18 text-white">
                      <use href="#icon_origin"></use>
                    </svg>
                  </div>
                  <p>Total Containers At Origin</p>
                </div>
                <h4 className="fw-700 fs-30 pt-1">
                  {
                    statusPieList?.find(
                      (item) => item?.status_name == "At port of loading"
                    )?.total_container
                  }
                  &nbsp;
                </h4>
              </div>
            </div>
          </div>
          <div className="col " style={{ height: "max-content" }}>
            <div className={`dash-card ${isLoading ? "skeleton" : ""}`}>
              <div className="line">
                <div className="d-flex align-items-center">
                  <div className="icon-box me-2">
                    <svg className="icon fs-18 text-white">
                      <use href="#icon_transit"></use>
                    </svg>
                  </div>
                  <p>Total Containers In Transit</p>
                </div>
                <h4 className="fw-700 fs-30 pt-1">
                  {
                    statusPieList?.find(
                      (item) => item?.status_name == "Ocean in transit"
                    )?.total_container
                  }
                  &nbsp;
                </h4>
              </div>
            </div>
          </div>
          <div className="col " style={{ height: "max-content" }}>
            <div className={`dash-card ${isLoading ? "skeleton" : ""}`}>
              <div className="line">
                <div className="d-flex align-items-center">
                  <div className="icon-box me-2">
                    <svg className="icon fs-22 text-white">
                      <use href="#icon_container_anchor"></use>
                    </svg>
                  </div>
                  <p>Total Containers Delivered</p>
                </div>
                <h4 className="fw-700 fs-30 pt-1">
                  {
                    statusPieList?.find(
                      (item) => item?.status_name == "Delivered"
                    )?.total_container
                  }
                  &nbsp;
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="row row-cols-2 row-cols-md-2 row-cols-lg-2 g-3 pb-4">
          <div className="col">
            <div className={`dash-card ${isLoading ? "skeleton" : ""}`}>
              <h6 className="fw-700 fs-22">Status</h6>
              <ApexChart statusPieList={statusPieList} />
            </div>
          </div>
          <div className="col" style={{ minHeight: "350px" }}>
            <div className={`dash-card ${isLoading ? "skeleton" : ""}`}>
              <h6 className="fw-700 fs-22">Container Type</h6>
              <TypeChart containerTypeList={containerTypeList} />
            </div>
          </div>
          <div className="col" style={{ minHeight: "350px" }}>
            <div className={`dash-card ${isLoading ? "skeleton" : ""}`}>
              <h6 className="fw-700 fs-22">Container By Country</h6>
              <CountryChart countryContainerList={countryContainerList} />
            </div>
          </div>
          <div className="col" style={{ minHeight: "350px" }}>
            <div className={`dash-card ${isLoading ? "skeleton" : ""}`}>
              <h6 className="fw-700 fs-22">Shipping line vs Container</h6>
              <ShippingChart
                shippingLineContainerList={shippingLineContainerList}
              />
            </div>
          </div>
        </div>
        <Row className="mb-4">
          <Col sm={12}>
            <div className={`dash-card ${isLoading ? "skeleton" : ""}`}>
              <h6 className="fw-700 fs-22 pb-3">Global Map</h6>
              <div>
                Marker colors :
                <span className="mx-2">🟡 Yellow = Origin/Port of origin</span>
                <span className="mx-2">
                  🔴 Red = Trans Shipment/Current Position
                </span>
                <span className="mx-2">
                  🟢 Green = Destination/Port of Discharge
                </span>
              </div>
              <GMap dashboardRawData={dashboardRawDataMap} />
            </div>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col sm={12} className="normal-tbl">
            <div className={`dash-card ${isLoading ? "skeleton" : ""}`}>
              <h6 className="fw-700 fs-22 pb-3">Arrival To Warehouse</h6>
              <table>
                <tr className="tr-bg">
                  <th>Booking Number</th>
                  <th>Total Containers</th>
                  <th>POL</th>
                  <th>POD</th>
                  <th>Pedimento</th>
                  <th>Vendors</th>
                </tr>
                {dashboardRawData?.map((book) => (
                  <tr>
                    <td>{book?.booking_id}</td>
                    <td>{book?.total_container}</td>
                    <td>{book?.port_of_loading}</td>
                    <td>{book?.port_of_discharge}</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                ))}
              </table>
            </div>
          </Col>
        </Row>
      </Container>

      {/* chat ui  */}

      <button type="button" className="flot-btn" onClick={handleShow}>
        <img src={window.location.origin + "/images/flot.png"} alt="flot" />
      </button>

      <div className={`${show ? "chat-container active" : "chat-container"}`}>
        <div className="chat-header">
          <div className="me-auto d-flex align-items-center gap-2">
            <button type="button " disabled>
              {/* <CustomIcon name="icon_chat" className="icon-1p75" /> */}
              <img
                className="chat-avatar"
                style={{
                  scale: "1.2",
                }}
                src="images/sender.png"
                alt="flot"
              />
            </button>
            <div className="d-inline">
              <p className="fw-500">Chat With MTM AI</p>
              <select
                value={chat_lang}
                onChange={(e) => setChat_lang(e.target.value)}
                name="chat-lang"
                id="chat-lang"
              >
                <option value="English">English</option>
                <option value="Spanish">Spanish</option>
              </select>
            </div>
          </div>
          <div className="ms-auto d-flex align-items-center gap-2">
            <button type="button" onClick={fullSize}>
              {showicon ? (
                <CustomIcon name="icon_minimize" className="icon-1p75" />
              ) : (
                <CustomIcon name="icon_maximize" className="icon-1p75" />
              )}
            </button>
            <button type="button" className="ms-2" onClick={handleClose}>
              <CustomIcon name="icon_close" className="icon-1p75" />
            </button>
          </div>
        </div>
        <div ref={chatLoadingRef} className="chat-body">
          {conditionalExp(
            userData?.default_language,
            <ul className="chat-list">
              {chatData.map((chat) => {
                if (chat?.type == "sender") {
                  return (
                    <li className="chat-sender">
                      <div className="chat-bubble">
                        <img
                          className="chat-avatar"
                          src="images/sender.png"
                          alt="flot"
                        />
                        <div
                          className="chat-content"
                          data-time={moment(chat?.timestamp).format("h:mm A")}
                        >
                          {chat?.message}
                        </div>
                        {/* <div className="like-content">
                  <span>Is this useful?</span>
                  <button type="buttun" className="like mx-2">
                      <CustomIcon name="icon_like"/>
                  </button>
                  <button type="buttun" className="dislike me-2">
                      <CustomIcon name="icon_dislike"/>
                  </button>
                </div> */}
                      </div>
                    </li>
                  );
                } else if (chat?.type == "receiver") {
                  return (
                    <li className="chat-reciever">
                      <div className="chat-bubble">
                        {/* <img
                          className="chat-avatar"
                          src="images/user.png"
                          alt="flot"
                        /> */}
                        <svg
                          className="icon rounded-5  chat-avatar"
                          style={{ color: "#1a31b2", scale: "1.2" }}
                        >
                          <use href="#icon_user_filled"></use>
                        </svg>
                        <div
                          className="chat-content"
                          data-time={moment(chat?.timestamp).format("h:mm A")}
                        >
                          {chat?.message}
                        </div>
                      </div>
                    </li>
                  );
                } else if (chat?.type == "error") {
                  return (
                    <li className="chat-sender">
                      <div className="chat-bubble">
                        <img
                          className="chat-avatar"
                          src="images/sender.png"
                          alt="flot"
                        />

                        <div
                          className="chat-content text-danger "
                          data-time={moment(chat?.timestamp).format("h:mm A")}
                        >
                          <b>
                            <i> {chat?.message}</i>
                          </b>
                        </div>
                      </div>
                    </li>
                  );
                }
              })}
              {isLoadingChat && (
                <li className="chat-sender">
                  <div className="chat-bubble">
                    <img
                      className="chat-avatar"
                      src="images/sender.png"
                      alt="flot"
                    />
                    <div data-time="" className=" typing">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </li>
              )}
            </ul>,
            <div className="h-100 d-flex flex-column p-4 justify-content-center">
              <p className="fw-700">In which language you want to chat?</p>
              <button
                className="tracking-btn my-1 w-75"
                style={{
                  background: conditionalExp(
                    chat_lang == "English",
                    "linear-gradient(90deg, #0E2078 -0.3%, #112ED8 130.42%)",
                    "unset"
                  ),
                  color: conditionalExp(
                    chat_lang == "English",
                    "#FFFFFF",
                    "unset"
                  ),
                }}
                onClick={() => setChat_lang("English")}
              >
                English
              </button>
              <button
                className="tracking-btn my-1 w-75"
                style={{
                  background: conditionalExp(
                    chat_lang == "Spanish",
                    "linear-gradient(90deg, #0E2078 -0.3%, #112ED8 130.42%)",
                    "unset"
                  ),
                  color: conditionalExp(
                    chat_lang == "Spanish",
                    "#FFFFFF",
                    "unset"
                  ),
                }}
                onClick={() => setChat_lang("Spanish")}
              >
                Spanish
              </button>
            </div>
          )}
        </div>
        <form className="chat-footer">
          <input
            type="text"
            required
            value={chatQuery}
            onChange={(e) => setChatQuery(e.target.value)}
            placeholder="Write Something..."
          />
          <button
            onClick={handleChatSend}
            // onClick={()=>chatLoadingRef.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })}
            disabled={
              !userData?.default_language || !chatQuery || isLoadingChat
            }
            type="submit"
          >
            <CustomIcon name="icon_send" className="fs-20" />
          </button>
        </form>
      </div>

      <ErrorModal
        error={error}
        setShowErrModal={setShowErrModal}
        showErrModal={showErrModal}
      />
    </>
  );
}

export default Dashboard;
