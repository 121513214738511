import { useState } from "react";
import axios from "axios";
import { Container, Row, Col, Modal, Button } from 'react-bootstrap'
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../components/Loader";
import validator from "validator";
import CryptoJS from "crypto-js";
import Select from "react-select";



function CompanySettings() {
  const navigate = useNavigate();
  const [showAddUserModal, setShowAddUserModal] = useState(false)
  const [selectedUserType, setSelectedUserType] = useState()
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAdding, setIsLoadingAdding] = useState(false)
  const [selectedUser, setSelectedUser] = useState()
  const [error, seterror] = useState();
  const [showErrModal, setShowErrModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false)


  const [addNewUserDetails, setaddNewUserDetails] = useState({
    name:"",
    email:"",
    phone:"",
    password:""
  })
  const [addNewUserErrors, setaddNewUserErrors] = useState({
    name:false,
    email:false,
    phone:false,
    password:false
  })

  function smallCheckFnForUser() {
    let name =
      addNewUserDetails?.name?.trim() == "" ? true : false;
    let email =
      !validator.isEmail(addNewUserDetails?.email);
    let phone =
      addNewUserDetails?.phone?.trim() == "" ? true : false;
    let password =
      !validator.isStrongPassword(addNewUserDetails?.password,{minLength:5,minUppercase:1,minSymbols:1,minNumbers:0})
   console.log(addNewUserDetails?.password,validator.isStrongPassword(addNewUserDetails?.password,{minLength:5,minUppercase:1,minSymbols:1,minNumbers:0}));
    if (name || email || phone || password) {
      setaddNewUserErrors({
        name,
        email,
        phone,
        password,
      });
      return true;
    } else {
      return false;
    }
  }


  function handleCloseAddUserModal() {
    setShowAddUserModal(false)
    setSelectedUserType()
    setaddNewUserDetails({
      name:"",
      email:"",
      phone:"",
      password:""
    })
    setaddNewUserErrors({
      name:false,
      email:false,
      phone:false,
      password:false
    })
  }
  const [warehouseUsers, setWarehouseUsers] = useState([])
  const userTypes = [
    {  label: "Control Table", value: 6 },
    {  label: "Distribution", value: 7 },
  ];

  const getWarehouseUsers = async () => {
    setIsLoading(true)
    const token = localStorage.getItem("mtm_user_token");
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmwarehouse/getUsers`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        
      });
      if (response.status == 200 && !response.data.error) {
        let userList = response.data.users?.map(user =>({label:user.user_full_name,value:user.id}))
        setWarehouseUsers([{label:"Add User",value:'Add User'},...userList]);
      } 
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      console.log(error);
      seterror("Error: " + error);
      setShowErrModal(true);
    } finally {
      setIsLoading(false);
    }
  };

  async function handleAddUser(e){
    e.preventDefault()

    if (smallCheckFnForUser()) {
      return;
    }

    setIsLoading(true)
    setIsLoadingAdding(true)
    const token = localStorage.getItem("mtm_user_token");
    try {
      const cipherText = CryptoJS.AES.encrypt(
        addNewUserDetails.password,
        process.env.REACT_APP_SECRET
      );
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmwarehouse/addNewUser`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        data: {
          user_type: selectedUserType?.value,
          name:addNewUserDetails?.name,
          email: addNewUserDetails?.email,
          phone: addNewUserDetails?.phone,
          password: cipherText.toString()
        },
      });
      if (response.status == 200 && !response.data.error) {
        setSelectedUser({label:response?.data?.user?.user_full_name,value:response.data?.user?.id})
        await getWarehouseUsers()
      } 
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      console.log(error);
      seterror("Error: " + error);
      setShowErrModal(true);
    } finally {
      setIsLoading(false);
      setIsLoadingAdding(false);
      handleCloseAddUserModal()
    }
  };
  const handleAddNewUserChange = (e) => {
    setaddNewUserDetails({
      ...addNewUserDetails,
      [e.target.id]: e.target.value,
    });
    setaddNewUserErrors({
      ...addNewUserErrors,
      [e.target.id]: false,
    });
  };
  return (
    <>
    <Container>
      <Row>
        <Col sm={12}>
          <h2 className='fw-700 fs-30 py-3'>ERPs</h2>
          <div className='d-flex gap-4 w-100 overflow-auto pb-4'>
            <div className='radio-card'>
              <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
              <label className="form-check-label" for="flexRadioDefault1"></label>
              <div className='d-flex justify-content-center align-items-center'>
                <div className='img-box'>
                  <img src='images/radio-1.png' alt='...'/>
                </div>
              </div>
            </div>
            <div className='radio-card'>
              <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"/>
              <label className="form-check-label" for="flexRadioDefault2"></label>
              <div className='d-flex justify-content-center align-items-center'>
                <div className='img-box'>
                  <img src='images/radio-2.png' alt='...'/>
                </div>
              </div>
            </div>
            <div className='radio-card'>
              <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3"/>
              <label className="form-check-label" for="flexRadioDefault3"></label>
              <div className='d-flex justify-content-center align-items-center'>
                <div className='img-box'>
                  <img src='images/radio-3.png' alt='...'/>
                </div>
              </div>
            </div>
            <div className='radio-card'>
              <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4"/>
              <label className="form-check-label" for="flexRadioDefault4"></label>
              <div className='d-flex justify-content-center align-items-center'>
                <div className='img-box'>
                  <img src='images/radio-2.png' alt='...'/>
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex py-4'>
            <div className='ms-auto'>
              <button type='button' className='grd-btn rounded-pill py-2' onClick={()=>setShowAddUserModal(true)}>
                <svg className="icon fs-24 me-1">
                  <use href="#icon_plus"></use>
                </svg>
                 Add
              </button>
            </div>
          </div>
        </Col>
      </Row>
      <Row className='mb-4'>
        <Col sm={12} className='normal-tbl'>
          <table>
            <tr className='tr-bg'>
              <th>Serial Number</th>
              <th>Name</th>
              <th>Username</th>
              <th>Primary</th>
              <th></th>
            </tr>
            <tr>
              <td>01</td>
              <td>Vineet Joshi</td>
              <td>vj@gamil.com</td>
              <td>YES</td>
              <td>
                <div className='d-flex'>
                  <button type='button' className='edit-btn'>
                    <svg className="icon">
                      <use href="#icon_edit"></use>
                    </svg>
                  </button>
                  <button type='button' className='delete-btn mx-auto'>
                    <svg className="icon">
                      <use href="#icon_delete"></use>
                    </svg>
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <td>01</td>
              <td>Vineet Joshi</td>
              <td>vj@gamil.com</td>
              <td>YES</td>
              <td>
                <div className='d-flex'>
                  <button type='button' className='edit-btn'>
                    <svg className="icon">
                      <use href="#icon_edit"></use>
                    </svg>
                  </button>
                  <button type='button' className='delete-btn mx-auto'>
                    <svg className="icon">
                      <use href="#icon_delete"></use>
                    </svg>
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <td>01</td>
              <td>Vineet Joshi</td>
              <td>vj@gamil.com</td>
              <td>YES</td>
              <td>
                <div className='d-flex'>
                  <button type='button' className='edit-btn'>
                    <svg className="icon">
                      <use href="#icon_edit"></use>
                    </svg>
                  </button>
                  <button type='button' className='delete-btn mx-auto'>
                    <svg className="icon">
                      <use href="#icon_delete"></use>
                    </svg>
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <td>01</td>
              <td>Vineet Joshi</td>
              <td>vj@gamil.com</td>
              <td>YES</td>
              <td>
                <div className='d-flex'>
                  <button type='button' className='edit-btn'>
                    <svg className="icon">
                      <use href="#icon_edit"></use>
                    </svg>
                  </button>
                  <button type='button' className='delete-btn mx-auto'>
                    <svg className="icon">
                      <use href="#icon_delete"></use>
                    </svg>
                  </button>
                </div>
              </td>
            </tr>  
          </table>
        </Col>
      </Row>
    </Container>
    {/* Modal */}
    <Modal show={showAddUserModal} onHide={handleCloseAddUserModal}   centered>
      <Modal.Header className="border-0" closeButton></Modal.Header>
        <Modal.Title >
          <p className="fs-4 text-center">Add</p></Modal.Title>
        <Modal.Body className="p-4">
          <form onSubmit={handleAddUser} className="d-flex justify-content-center mt-2 flex-column">
          <div className="input-box mb-3">
          {addNewUserDetails.name &&
            addNewUserDetails.name != "" && (
              <label htmlFor="name"> Name*</label>
            )}
          <input
            type="text"
            className="w-100"
            onChange={handleAddNewUserChange}
            id="name"
            value={addNewUserDetails.name}
            placeholder="Enter Name*"
          />
        </div>
        {addNewUserErrors.name && (
          <div className="text-danger mb-3">
            Please enter valid name 
          </div>
        )}
          <div className="input-box mb-3">
          {addNewUserDetails.email &&
            addNewUserDetails.email != "" && (
              <label htmlFor="email"> Email*</label>
            )}
          <input
            type="email"
            className="w-100"
            onChange={handleAddNewUserChange}
            id="email"
            value={addNewUserDetails.email}
            placeholder="Enter  Email*"
          />
        </div>
        {addNewUserErrors.email && (
          <div className="text-danger mb-3">
            Please enter valid email address
          </div>
        )}
          <div className="input-box mb-3">
          {addNewUserDetails.phone &&
            addNewUserDetails.phone != "" && (
              <label htmlFor="phone"> Phone *</label>
            )}
          <input
            type="number"
            className="w-100"
            onChange={handleAddNewUserChange}
            id="phone"
            value={addNewUserDetails.phone}
            placeholder="Enter  Phone*"
          />
        </div>
        {addNewUserErrors.phone && (
          <div className="text-danger mb-3">
            Please enter phone number
          </div>
        )}
        <div className="input-box mb-3">
          {addNewUserDetails.password &&
            addNewUserDetails.password != "" && (
              <label htmlFor="password"> Password*</label>
            )}
          <div className="position-relative">
          <input
            type={showPassword ? "text":"password"}
            className="w-100"
            onChange={handleAddNewUserChange}
            id="password"
            value={addNewUserDetails.password}
            placeholder="Enter Password*"
          />
           <button
           className="vision"
        type="button"
        onClick={() => setShowPassword(!showPassword)}
      >
       {!showPassword ? (
                      <svg className="icon">
                        <use href="#icon_vision_withoutslash"></use>
                      </svg>
                    ) : (
                      <svg className="icon">
                        <use href="#icon_vision"></use>
                      </svg>
                    )}
      </button>
          </div>
        </div>
        {addNewUserErrors.password && (
          <div className="text-danger mb-3">
            <p>Please enter strong password.</p>
            <small><i>**note: password should contain atleast 5 characters,1 uppercase letter & 1 special character</i></small> 
          </div>
        )}
          <div className="mb-3">
          {selectedUserType && (
            <label className="mb-1">User Type</label>
          )}
          <Select
          required
            value={selectedUserType}
            onChange={option=> {setSelectedUserType(option)}}
            options={userTypes}
            placeholder="select user type"
          />
          
        </div>
            <Button
              disabled={isLoadingAdding}
             type="submit"
             className="align-self-end"
            >
              {!isLoadingAdding ? "Submit" : <Loader />}
            </Button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default CompanySettings