import { Outlet, useLocation, useNavigate } from "react-router-dom";
import NavBar from "../components/NavBar";
import AsideBar from "../components/AsideBar";
import { useContext, useEffect } from "react";
import { CustomerContext } from "../context/customerContext";

function LayoutOutlet() {
    const navigate = useNavigate();
    const location = useLocation();

    const { customer, updateCustomer} = useContext(CustomerContext)
    useEffect(() => {
        if (!localStorage.getItem("mtm_user_token") || !localStorage.getItem("mtm_user_data")) {
            navigate("/login");
            return
          }
        if(!customer?.ctMenus?.find(menu=> menu?.menu_name == 'Dashboard')?.isAllowed && location.pathname == '/'&& customer?.ctMenus) {
            let allowedMenu = customer?.ctMenus?.find(menu=> menu?.isAllowed == true)
            if(allowedMenu){
                switch (allowedMenu.menu_name) {
                    case 'Tracking':
                        navigate('/tracking')
                        break;
                    
                    case 'SKU Cost Footprint':
                        navigate('/skufootprint')
                        break;
                    case 'SKU Management':
                        navigate('/managesku')
                        break;
                    
                    default:
                        navigate('/unauthorized')
                        break;
                }
            }else{
                navigate('/unauthorized')
            }
           
        }else if(!customer?.ctMenus?.find(menu=> menu?.menu_name == 'Tracking')?.isAllowed && location.pathname == '/tracking' && customer?.ctMenus) {
            navigate('/unauthorized')
        }else if(!customer?.ctMenus?.find(menu=> menu?.menu_name == 'SKU Cost Footprint')?.isAllowed && location.pathname == '/skufootprint' && customer?.ctMenus) {
            navigate('/unauthorized')
        }else if(!customer?.ctMenus?.find(menu=> menu?.menu_name == 'SKU Management')?.isAllowed && location.pathname == '/managesku' && customer?.ctMenus) {
            navigate('/unauthorized')
        }else if(!customer?.ctMenus?.find(menu=> menu?.menu_name == 'Demand Forecast')?.isAllowed && location.pathname == '/demandforcast' && customer?.ctMenus) {
            navigate('/unauthorized')
        }
    }, [customer])
    
    return(<>
        <AsideBar></AsideBar>
        <NavBar></NavBar>
        <div  className="wrapper">
            <Outlet></Outlet>
        </div>
    </>)
}
export default LayoutOutlet;