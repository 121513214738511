import { BrowserRouter, Routes, Route,  } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import Login from "../pages/Login";
import LayoutOutlet from "./LayoutOutlet";
import LayoutOutlet2 from "./LayoutOutlet2";
import LayoutOutlet3 from "./LayoutOutlet3";
import Home from "../pages/Home";
import Dashboard from "../pages/Dashboard";
import CompanySettings from "../pages/CompanySettings";
import Booking_number from "../pages/Booking_number";
import { ContainerProvider } from "../context/containerContext";
import { CustomerProvider } from "../context/customerContext";
import SkuFootprint from "../pages/SKU_cost_footprint";
import ProfileSettings from "../pages/ProfileSettings";
import ResetPassword from "../pages/ResetPassword";
import Unauthorized from "../pages/Unauthorized";
import SkuManagement from "../pages/SKUManagement";
import DemandForcast from "../pages/DemandForcast";
import ForcastPeriod from "../pages/ForcastPeriod";

function Routing() {
  
  return (
    <>
      <CustomerProvider>
      <ContainerProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />}></Route>
            <Route path="/unauthorized" element={<Unauthorized />}></Route>
            <Route path="/" element={<LayoutOutlet />}>
              <Route index element={<Dashboard />} />
              <Route path="/" element={<Dashboard />} />
              <Route path="/tracking" element={<Home />} />
              <Route path="/skufootprint" element={<SkuFootprint />} />
              <Route path="/managesku" element={<SkuManagement />} />
              <Route path="/demandforcast" element={<DemandForcast />} />
            </Route>
            <Route path="/" element={<LayoutOutlet2 />}>
              <Route path="/company-settings" element={<CompanySettings />} />
              <Route
                path="/tracking/booking_number/:shipment_id"
                element={<Booking_number />}
              />
            </Route>
            <Route path="/" element={<LayoutOutlet3 />}>
              <Route path='/profile-settings' element={<ProfileSettings />}/>
              <Route path='/reset-password' element={<ResetPassword />}/>
              <Route path='/forcast-period' element={<ForcastPeriod />}/>
            </Route>
          </Routes>
        </BrowserRouter>
      </ContainerProvider>
      </CustomerProvider>
    </>
  );
}

export default Routing;
