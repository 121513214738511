import {Link, NavLink, useNavigate, useLocation} from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
import { CustomIcon } from '../components/Common';
import { useContext } from "react";
import { CustomerContext } from "../context/customerContext";


function AsideBar() {
    const location = useLocation();
const navigate = useNavigate();
const { customer, updateCustomer} = useContext(CustomerContext)
    return(<>
    <aside className="aside-form">
        <div>
            <ul>
                <li>
                    <button onClick={()=> navigate('/')} style={{display:customer?.ctMenus?.find(menu=> menu?.menu_name == 'Dashboard')?.isAllowed ? 'initial' : 'none',backgroundColor: location.pathname == '/'? '#FAFAFA': 'transparent',color: location.pathname == '/'? '#1934B5': '#FAFAFA'}}>
                        <svg className="icon fs-24">
                            <use href="#icon_dashboard"></use>
                        </svg>
                        <span className="d-block">Dashboard</span>
                    </button>
                </li>
                <li>
                    <button onClick={()=> navigate('/tracking')} style={{display:customer?.ctMenus?.find(menu=> menu?.menu_name == 'Tracking')?.isAllowed ? 'initial' : 'none',backgroundColor: location.pathname == '/tracking'? '#FAFAFA': 'transparent',color: location.pathname == '/tracking'? '#1934B5': '#FAFAFA'}}>
                        <svg className="icon fs-24">
                            <use href="#icon_track"></use>
                        </svg>
                        <span className="d-block">Tracking</span>
                    </button>
                </li>
                <li>
                    <button onClick={()=> navigate('/skufootprint')} style={{display:customer?.ctMenus?.find(menu=> menu?.menu_name == 'SKU Cost Footprint')?.isAllowed ? 'initial' : 'none',backgroundColor: location.pathname == '/skufootprint'? '#FAFAFA': 'transparent',color: location.pathname == '/skufootprint'? '#1934B5': '#FAFAFA'}}>
                        <svg className="icon fs-24">
                            <use href="#icon_sku_cost"></use>
                        </svg>
                        <span className="d-block">SKU Cost Footprint</span>
                    </button>
                </li>
                <li>
                    <button onClick={()=> navigate('/managesku')} style={{display:customer?.ctMenus?.find(menu=> menu?.menu_name == 'SKU Management')?.isAllowed ? 'initial' : 'none',backgroundColor: location.pathname == '/managesku'? '#FAFAFA': 'transparent',color: location.pathname == '/managesku'? '#1934B5': '#FAFAFA'}}>
                        <svg className="icon fs-24">
                            <use href="#icon_sku_management"></use>
                        </svg>
                        <span className="d-block text-break">SKU Management</span>
                    </button>
                </li>
                <li>
                    <button onClick={()=> navigate('/demandforcast')} style={{display:customer?.ctMenus?.find(menu=> menu?.menu_name == 'Demand Forecast')?.isAllowed && customer?.demand_forcast_type ? 'initial' : 'none',backgroundColor: location.pathname == '/demandforcast'? '#FAFAFA': 'transparent',color: location.pathname == '/demandforcast'? '#1934B5': '#FAFAFA'}}>
                        <svg className="icon fs-24">
                            <use href="#icon_forcast_demand"></use>
                        </svg>
                        <span className="d-block text-break">Demand Forecast</span>
                    </button>
                </li>
            </ul>
        </div>
        
    </aside>
    </>)
}
export default AsideBar