import { Outlet, useNavigate } from "react-router-dom";
import NavBar from "../components/NavBar";
import AsideBar2 from "./AsideBar2";
import { useEffect } from "react";

function LayoutOutlet2() {
    const navigate = useNavigate()
    useEffect(() => {
        if (!localStorage.getItem("mtm_user_token") || !localStorage.getItem("mtm_user_data")) {
            navigate("/login");
            return
          }
    }, [])
    return(<>
        <AsideBar2></AsideBar2>
        <NavBar></NavBar>
        <div className="wrapper-content">
            <Outlet></Outlet>
        </div>
    </>)
}
export default LayoutOutlet2;