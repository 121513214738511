import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const colors = ['#66C9D7'];

function ShippingChart({shippingLineContainerList}) {
  const [chartData,setChartData] = useState({
    series: [
      {
        data: [26, 13, 25, 32, 33, 47, 31, 20],
      },
    ],
    fill: {
        colors: ['#00A6FF'], // Set the light fill color for lines
        opacity: 0.8, // Set opacity to 1 (fully opaque)
      },
    options: {
      chart: {
        type: 'bar',
        events: {
          click: function (chart, w, e) {
            // console.log(chart, w, e)
          },
        },
      },
      plotOptions: {
        bar: {
          columnWidth: '50%',
          distributed: false,
          borderRadius: 15,
        },
      },
      dataLabels: {
        enabled: true,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: [
          ['MSCU'],
          ['HL'],
          ['MSCU'],
          ['HL'],
          ['MSCU'],
          ['HL'],
          ['MSCU'],
          ['HL'],
        ],
        labels: {
          style: {
            fontSize: '10px',
            fontWeight: 'bold'
          },
        },
      },
    },
  });

  useEffect(() => {
   
    const series = shippingLineContainerList?.map(item=> {
      const percentage =
      item?.total_container 
    return {
      career_scac: item?.career_scac,
      percentage: percentage,
    }
    })

    setChartData(pre => ({...pre,series:[{data:series.map(item=>item?.percentage)}],options:{...pre.options,xaxis:{...pre.options.xaxis,categories:series.map(item=>item?.career_scac )}}}));
    
  
  }, [shippingLineContainerList])
  return (
    <div id="chart">
      {shippingLineContainerList?.length ? <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={280} /> : <div >No Shipping line vs Container Data Found For Selected Date</div>}
    </div>
  );
}

export default ShippingChart;
