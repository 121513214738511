import React, { useContext, useState } from "react";
import { Link, NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { ContainerContext } from "../context/containerContext";
import { CustomerContext } from "../context/customerContext";

function AsideBar3() {
    const navigate = useNavigate()
const { customer, updateCustomer} = useContext(CustomerContext)

  return (
    <>
    <div className="aside-new" id="asideBar">
        <ul>
    <div onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
                <button type="button" className="back-btn">
                  <svg className="icon  fs-22">
                    <use href="#icon_leftarrow"></use>
                  </svg>
                <strong className="fs-14">Back</strong>
                </button>
              </div>
            <li>
                <NavLink className="nav-link" to="/profile-settings" >
                    <span>
                        <svg className="icon">
                            <use href="#icon_profile"></use>
                        </svg>
                    </span>
                    <strong>Profile</strong>
                </NavLink>
            </li>
            <li>
                <NavLink className="nav-link" to="/reset-password" >
                    <span>
                        <svg className="icon">
                            <use href="#icon_key"></use>
                        </svg>
                    </span>
                    <strong>Security</strong>
                </NavLink>
            </li>
            <li>
                <NavLink className="nav-link" style={{display:customer?.ctMenus?.find(menu=> menu?.menu_name == 'Demand Forecast')?.isAllowed ? 'flex' : 'none'}}  to="/forcast-period" >
                    <span>
                        <svg className="icon">
                            <use href="#icon_forcast_period"></use>
                        </svg>
                    </span>
                    <strong>Forecast Period</strong>
                </NavLink>
            </li>
        </ul>
    </div>
    </>
  );
}

export default AsideBar3;
