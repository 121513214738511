import React, { useState, useEffect, useRef } from "react";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import {
  Container,
  Row,
  Col,
  Tab,
  Tabs,
  Form,
  Modal,
  Button,
} from "react-bootstrap";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import moment from "moment/moment";
import { getStatusIcon } from "../utils/commonFn";

function Home() {
  const navigate = useNavigate();
  const [bookingListUpcoming, setbookingListUpcoming] = useState([]);
  const [bookingListPast, setbookingListPast] = useState([]);
  const [gettingBookingList, setgettingBookingList] = useState(false);
  const getAllBookingList = async () => {
    setgettingBookingList(true);
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmbooking/getBookingList`,
        method: "POST",
        headers: {
          jwt_token: localStorage.getItem("mtm_user_token"),
        },
        data: {
          customer_id: JSON.parse(localStorage.getItem("mtm_user_data"))
            ?.customer_id,
        },
      });
      if (response.status == 200) {
        setgettingBookingList(true);
        if (!response.data.error) {
          setbookingListUpcoming(
            response?.data?.bookings?.filter((b) => !b?.isDelivered)
          );
          setbookingListPast(
            response?.data?.bookings?.filter((b) => b?.isDelivered)
          );
        }
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    } finally {
      setgettingBookingList(false);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("mtm_user_token");
    if (!token) {
      navigate("/login");
    } else {
      getAllBookingList();
    }
  }, []);

  function conditionalExp(condition, ifblock, elseblock) {
    if (condition) {
      return ifblock;
    } else {
      return elseblock;
    }
  }

  function mapLoadingDivs() {
   
      return  <>
      <div className="col ">
        <div class="card w-100 skeleton rounded-lg">
          <div class="card-box p-0">
            <div className="bg-lightblue p-2 rounded-2 d-flex align-items-center text-nowrap icon-box">
              <div className="w30px h30px bg-blue rounded-2 d-flex align-items-center justify-content-center me-2">
                <svg className="icon fs-22 text-white">
                  <use href="#icon_transit"></use>
                </svg>
              </div>
              <span className="fw-600 text-blue">In Transit</span>
            </div>
            <ul>
              <li>
                <strong>Booking Date: </strong>
                <span>20/08/2023</span>
              </li>
              <li>
                <strong>Type: </strong>
                <span>Land</span>
              </li>
              <li>
                <strong>Origin: </strong>
                <span>Abc, Xyz</span>
              </li>
              <li>
                <strong>Booking Number: </strong>
                <span>002255</span>
              </li>
              <li>
                <strong>Carrier: </strong>
                <span>Abc</span>
              </li>
              <li>
                <strong>Number of containers: </strong>
                <span>5</span>
              </li>
            </ul>
            <div className="btn-box">
              <button
                type="button"
                className="tracking-btn d-flex justify-content-center px-3 w-100"
              >
                <span>Tracking</span>
                <svg className="icon fs-22">
                  <use href="#icon_rightarrow"></use>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="col ">
        <div class="card w-100 skeleton rounded-lg">
          <div class="card-box p-0">
            <div className="bg-lightcyan p-2 rounded-2 d-flex align-items-center text-nowrap icon-box">
              <div className="w30px h30px bg-cyan rounded-2 d-flex align-items-center justify-content-center me-2">
                <svg className="icon fs-22 text-white">
                  <use href="#icon_portloding"></use>
                </svg>
              </div>
              <span className="fw-600 text-cyan">Port of Loading</span>
            </div>
            <ul>
              <li>
                <strong>Booking Date: </strong>
                <span>20/08/2023</span>
              </li>
              <li>
                <strong>Type: </strong>
                <span>Land</span>
              </li>
              <li>
                <strong>Origin: </strong>
                <span>Abc, Xyz</span>
              </li>
              <li>
                <strong>Booking Number: </strong>
                <span>002255</span>
              </li>
              <li>
                <strong>Carrier: </strong>
                <span>Abc</span>
              </li>
              <li>
                <strong>Number of containers: </strong>
                <span>5</span>
              </li>
            </ul>
            <div className="btn-box">
              <button
                type="button"
                className="tracking-btn d-flex justify-content-center px-3 w-100"
              >
                <span>Tracking</span>
                <svg className="icon fs-22">
                  <use href="#icon_rightarrow"></use>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="col ">
        <div class="card w-100 skeleton rounded-lg">
          <div class="card-box p-0">
            <div className="bg-lightpurple p-2 rounded-2 d-flex align-items-center text-nowrap icon-box">
              <div className="w30px h30px bg-purple rounded-2 d-flex align-items-center justify-content-center me-2">
                <svg className="icon fs-22 text-white">
                  <use href="#icon_custom"></use>
                </svg>
              </div>
              <span className="fw-600 text-purple">At Customs</span>
            </div>
            <ul>
              <li>
                <strong>Booking Date: </strong>
                <span>20/08/2023</span>
              </li>
              <li>
                <strong>Type: </strong>
                <span>Land</span>
              </li>
              <li>
                <strong>Origin: </strong>
                <span>Abc, Xyz</span>
              </li>
              <li>
                <strong>Booking Number: </strong>
                <span>002255</span>
              </li>
              <li>
                <strong>Carrier: </strong>
                <span>Abc</span>
              </li>
              <li>
                <strong>Number of containers: </strong>
                <span>5</span>
              </li>
            </ul>
            <div className="btn-box">
              <button
                type="button"
                className="tracking-btn d-flex justify-content-center px-3 w-100"
              >
                <span>Tracking</span>
                <svg className="icon fs-22">
                  <use href="#icon_rightarrow"></use>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="col ">
        <div class="card w-100 skeleton rounded-lg">
          <div class="card-box p-0">
            <div className="bg-lightyellow p-2 rounded-2 d-flex align-items-center text-nowrap icon-box">
              <div className="w30px h30px bg-yellow rounded-2 d-flex align-items-center justify-content-center me-2">
                <svg className="icon fs-22 text-white">
                  <use href="#icon_house"></use>
                </svg>
              </div>
              <span className="fw-600 text-yellow">On Way To Warehouse</span>
            </div>
            <ul>
              <li>
                <strong>Booking Date: </strong>
                <span>20/08/2023</span>
              </li>
              <li>
                <strong>Type: </strong>
                <span>Land</span>
              </li>
              <li>
                <strong>Origin: </strong>
                <span>Abc, Xyz</span>
              </li>
              <li>
                <strong>Booking Number: </strong>
                <span>002255</span>
              </li>
              <li>
                <strong>Carrier: </strong>
                <span>Abc</span>
              </li>
              <li>
                <strong>Number of containers: </strong>
                <span>5</span>
              </li>
            </ul>
            <div className="btn-box">
              <button
                type="button"
                className="tracking-btn d-flex justify-content-center px-3 w-100"
              >
                <span>Tracking</span>
                <svg className="icon fs-22">
                  <use href="#icon_rightarrow"></use>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="col ">
        <div class="card w-100 skeleton rounded-lg">
          <div class="card-box p-0">
            <div className="bg-lightgreen p-2 rounded-2 d-flex align-items-center text-nowrap icon-box">
              <div className="w30px h30px bg-green rounded-2 d-flex align-items-center justify-content-center me-2">
                <svg className="icon fs-22 text-white">
                  <use href="#icon_delivered"></use>
                </svg>
              </div>
              <span className="fw-600 text-green">Delivered</span>
            </div>
            <ul>
              <li>
                <strong>Booking Date: </strong>
                <span>20/08/2023</span>
              </li>
              <li>
                <strong>Type: </strong>
                <span>Land</span>
              </li>
              <li>
                <strong>Origin: </strong>
                <span>Abc, Xyz</span>
              </li>
              <li>
                <strong>Booking Number: </strong>
                <span>002255</span>
              </li>
              <li>
                <strong>Carrier: </strong>
                <span>Abc</span>
              </li>
              <li>
                <strong>Number of containers: </strong>
                <span>5</span>
              </li>
            </ul>
            <div className="btn-box">
              <button
                type="button"
                className="tracking-btn d-flex justify-content-center px-3 w-100"
              >
                <span>Tracking</span>
                <svg className="icon fs-22">
                  <use href="#icon_rightarrow"></use>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="col ">
        <div class="card w-100 skeleton rounded-lg">
          <div class="card-box p-0">
            <div className="bg-lightgreen p-2 rounded-2 d-flex align-items-center text-nowrap icon-box">
              <div className="w30px h30px bg-green rounded-2 d-flex align-items-center justify-content-center me-2">
                <svg className="icon fs-22 text-white">
                  <use href="#icon_delivered"></use>
                </svg>
              </div>
              <span className="fw-600 text-green">Delivered</span>
            </div>
            <ul>
              <li>
                <strong>Booking Date: </strong>
                <span>20/08/2023</span>
              </li>
              <li>
                <strong>Type: </strong>
                <span>Land</span>
              </li>
              <li>
                <strong>Origin: </strong>
                <span>Abc, Xyz</span>
              </li>
              <li>
                <strong>Booking Number: </strong>
                <span>002255</span>
              </li>
              <li>
                <strong>Carrier: </strong>
                <span>Abc</span>
              </li>
              <li>
                <strong>Number of containers: </strong>
                <span>5</span>
              </li>
            </ul>
            <div className="btn-box">
              <button
                type="button"
                className="tracking-btn d-flex justify-content-center px-3 w-100"
              >
                <span>Tracking</span>
                <svg className="icon fs-22">
                  <use href="#icon_rightarrow"></use>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </> 
  
  }

  function mapBookingList(list) {
    return list.map((booking) => (
      <div className={`col ${gettingBookingList ? "skeleton" : ""}`}>
        <div class="card w-100 rounded-lg">
          <div class="card-box p-0">
            {getStatusIcon(booking?.status_name)}
            <ul>
              <li>
                <strong>Booking Date: </strong>
                <span>
                  {conditionalExp(
                    moment(booking?.booking_date).format("DD/MM/YYYY") ==
                      "Invalid date",
                    "Not Provided",
                    moment(booking?.booking_date).format("DD/MM/YYYY")
                  )}
                </span>
              </li>
              <li>
                <strong>Type: </strong>
                <span>{booking?.booking_type}</span>
              </li>
              <li>
                <strong>Origin: </strong>
                <span>{booking?.port_of_loading}</span>
              </li>
              <li>
                <strong>Booking Number: </strong>
                <span>{booking?.booking_id}</span>
              </li>
              <li>
                <strong>Carrier: </strong>
                <span>{booking?.career_scac}</span>
              </li>
              <li>
                <strong>Number of containers: </strong>
                <span>{booking?.total_container}</span>
              </li>
            </ul>
            <div className="btn-box">
              <button
                type="button"
                className="tracking-btn d-flex justify-content-center px-3 w-100"
                onClick={() => {
                  navigate(`booking_number/${booking?.shipment_id}`, {
                    state: {
                      booking: booking,
                    },
                  });
                }}
              >
                <span>Tracking</span>
                <svg className="icon fs-22">
                  <use href="#icon_rightarrow"></use>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    ));
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col className="col-12 py-3">
            <div className="w-100 tabs-row">
              <ul
                className="nav nav-pills mb-3 w-100"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-Upcoming-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-Upcoming"
                    type="button"
                    role="tab"
                    aria-controls="pills-Upcoming"
                    aria-selected="true"
                  >
                    <svg className="icon fs-22 me-2">
                      <use href="#icon_upcoming"></use>
                    </svg>
                    Upcoming
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-Past-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-Past"
                    type="button"
                    role="tab"
                    aria-controls="pills-Past"
                    aria-selected="false"
                  >
                    <svg className="icon fs-22 me-2">
                      <use href="#icon_past"></use>
                    </svg>
                    Past
                  </button>
                </li>
              </ul>
            </div>
            <div className="tab-content" id="pills-tabContent">
              
                <>
                  <div
                    className="tab-pane fade show active"
                    id="pills-Upcoming"
                    role="tabpanel"
                    aria-labelledby="pills-Upcoming-tab "
                    tabIndex="0"
                  >
                    {conditionalExp(
                      !bookingListUpcoming.length && !gettingBookingList,
                      <h1 className=" text-center">
                        No Upcoming Bookings Found
                      </h1>,
                      ""
                    )}
                    <div className="row rows-cols-1 row-cols-lg-3 g-4">
                     {conditionalExp((gettingBookingList && !bookingListUpcoming.length) , mapLoadingDivs() ,"")} 
                      {mapBookingList(bookingListUpcoming)}
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-Past"
                    role="tabpanel"
                    aria-labelledby="pills-Past-tab"
                    tabIndex="0"
                  >
                    {conditionalExp(
                      !bookingListPast.length && !gettingBookingList,
                      <h1 className=" text-center">No Past Bookings Found</h1>,
                      ""
                    )}
                    <div className="row rows-cols-1 row-cols-lg-3 g-4">
                    {conditionalExp((gettingBookingList && !bookingListPast.length) , mapLoadingDivs() ,"")} 
                      {mapBookingList(bookingListPast)}
                    </div>
                  </div>
                </>
              
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Home;
