import axios from "axios";
import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ErrorModal from "../components/ErrorModal";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import validator from 'validator';
import CryptoJS from "crypto-js";
import { SuccessModal } from "../components/SuccessModal";
function ResetPassword() {
  const [showPass, setShowPass] = useState(false);
  const [showPass1, setShowPass1] = useState(false);
  const [showPass2, setShowPass2] = useState(false);
  const [isContinue, setIsContinue] = useState(false);
  const mtm_user_data = JSON.parse(localStorage.getItem("mtm_user_data"));
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [error, setError] = useState();
  const [showErrModal, setShowErrModal] = useState(false);
  const [pass, setPass] = useState("");
  const [passOld, setPassOld] = useState("");
  const [otp, setOtp] = useState("")
  const [confPass, setConfPass] = useState("");
  const navigate = useNavigate();
  const [passError, setPassError] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false)
      const [sucessMsg, setSucessMsg] = useState("")
  async function sendOtpFn() {
    setIsLoading(true);

   
    try {
      const cipherText = CryptoJS.AES.encrypt(
        passOld,
        process.env.REACT_APP_SECRET
      );
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmcustomer/password/sendotp`,
        method: "POST",
        headers: {
          jwt_token: localStorage.getItem("mtm_user_token"),
        },
        data: { customer_user_id: mtm_user_data?.customer_user_id,oldPassword:cipherText.toString() },
      });
      if (response.status == 200 && !response.data.error) {
        setIsContinue(true);
      } else {
        setError({ title: "Error", err: response.data.message });
        setShowErrModal(true);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    } finally {
      setIsLoading(false);
    }
  }
  async function updatePassFn() {
    setIsLoadingSubmit(true);
    try {
      const cipherText = CryptoJS.AES.encrypt(
        pass,
        process.env.REACT_APP_SECRET
      );
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmcustomer/password/change`,
        method: "POST",
        headers: {
          jwt_token: localStorage.getItem("mtm_user_token"),
        },
        data: { customer_user_id: mtm_user_data?.customer_user_id, otp:otp, new_password:cipherText.toString() },
      });
      if (response.status == 200 && !response.data.error) {
        setIsContinue(false);
        setConfPass("")
        setPass("")
        setOtp("")
        setPassError("")
        setSucessMsg("Password updated successfully")
        setShowSuccessModal(true)
        setTimeout(() => {
          setSucessMsg('')
          setShowSuccessModal(false)
        }, 2000);

      } else {
        setError({ title: "Error", err: response.data.message });
        setShowErrModal(true);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    } finally {
      setIsLoadingSubmit(false);
    }
  }
  return (
    <>
      <Container fluid>
        <Row>
          <Col sm={12}>
            <h2 className="fw-700 fs-30 py-4">Reset Password</h2>
          </Col>
        </Row>
        <div className="row">
          <div className="col-sm-12 col-md-10 col-lg-8">
            <div className="row pb-4 g-4">
            <div className="col-sm-12">
                <label htmlFor="first_name" className="form-label fw-500 fs-18">
                  Old Password
                </label>
                <div className="position-relative">
                  <input
                    value={passOld}
                    onChange={(e) => {
                      setPassOld(e.target.value);
                    }}
                    type={showPass2 ? "text" : "password"}
                    placeholder="Enter Old Password"
                    className="form-control"
                    id="first_name"
                  />
                  <button
                    type="button"
                    onClick={() => setShowPass2((pre) => !pre)}
                    className="eye-btn"
                  >
                    <svg className="icon fs-18 position-absolute arrow">
                      <use href="#icon_vision"></use>
                    </svg>
                  </button>
                </div>
              </div>
              <div className="col-sm-12">
                <label htmlFor="first_name" className="form-label fw-500 fs-18">
                  New Password
                </label>
                <div className="position-relative">
                  <input
                    value={pass}
                    onChange={(e) => {
                      setPass(e.target.value);
                      if(validator.isStrongPassword(e.target.value,{pointsForContainingSymbol:50,pointsForContainingLower:30,pointsForContainingNumber:10,pointsForContainingUpper:10,returnScore:true}) <= 80 || e.target.value.length <= 6){
                        setPassError("Password must contain at least 6 characters and 1 special character.");
                      }else{
                        setPassError("");
                      }
                    }}
                    type={showPass ? "text" : "password"}
                    placeholder="Enter New Password"
                    className="form-control"
                    id="first_name"
                  />
                  <button
                    type="button"
                    onClick={() => setShowPass((pre) => !pre)}
                    className="eye-btn"
                  >
                    <svg className="icon fs-18 position-absolute arrow">
                      <use href="#icon_vision"></use>
                    </svg>
                  </button>
                </div>
                {passError && <p className="text-danger">{passError}</p>}
              </div>
              <div className="col-sm-12">
                <label htmlFor="last_name" className="form-label fw-500 fs-18">
                  Confirm Password
                </label>
                <div className="position-relative">
                  <input
                  disabled={!pass.length}
                    value={confPass}
                    onChange={(e) => setConfPass(e.target.value)}
                    type={showPass1 ? "text" : "password"}
                    placeholder="Enter Conform Password"
                    className="form-control"
                    id="last_name"
                  />
                  <button
                    type="button"
                    onClick={() => setShowPass1((pre) => !pre)}
                    className="eye-btn"
                  >
                    <svg className="icon fs-18 position-absolute arrow">
                      <use href="#icon_vision"></use>
                    </svg>
                  </button>
                </div>
                {confPass && pass !== confPass ? <p className="text-danger">Password does not match.</p> : ""}
              </div>
              {!isContinue ? (
                <div className="col-12">
                  <button
                    disabled={isLoading || (validator.isStrongPassword(pass,{pointsForContainingSymbol:50,pointsForContainingLower:30,pointsForContainingNumber:10,pointsForContainingUpper:10,returnScore:true}) <= 80 || pass.length <= 6) ||confPass.length <=6 || (pass !== confPass)}
                    type="button"
                    onClick={() => sendOtpFn()}
                    className="grd-btn rounded-pill py-1"
                  >
                    {isLoading ? <Loader /> : "Continue"}
                  </button>
                </div>
              ) : (
                <>
                  <div className="col-sm-12">
                    <p className="fw-700 fs-20 pb-3">
                      Enter the code we sent to {mtm_user_data?.user_email}
                    </p>
                    <label className="form-label fw-500 fs-18">
                      Enter Verification Code
                    </label>
                    <input
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                      type="number"
                      className="form-control"
                      placeholder="Enter Code"
                      id="otp"
                    />
                  </div>
                  <div className="col-12">
                    <button
                      disabled={isLoadingSubmit || !otp.length}
                      type="button"
                      className="grd-btn rounded-pill py-1"
                      onClick={updatePassFn}
                    >
                      {isLoadingSubmit ? <Loader /> : "Update Password"}
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Container>
      <ErrorModal
        error={error}
        setShowErrModal={setShowErrModal}
        showErrModal={showErrModal}
      />
    <SuccessModal handleCloseSuccess={()=>setShowSuccessModal(false)} showSuccess={showSuccessModal} successMessagePopup={sucessMsg}/>

    </>
  );
}

export default ResetPassword;
