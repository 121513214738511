
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const CountryChart = ({countryContainerList}) => {
  // Define the initial state
  const [chartData,setChartData] = useState({
    series: [{
      data: [],
    }],
    options: {
      chart: {
        type: 'bar',

      },
      plotOptions: {
        bar: {
        //   borderRadius: 4,
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: true,
      },
      xaxis: {
        categories: [],
      },
      grid: {
        show: false, // Remove background grid lines
      },
      fill: {
        colors: ['#1935B7'], // Set the light fill color for lines
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 50, 100]
        }
      },
    }
  });

  useEffect(() => {
    console.log(countryContainerList);
    
    const series = countryContainerList?.map(item=> {
      const percentage =
      item?.total_container 
    return {
      country: item?.country,
      percentage: percentage,
    }
    })

    setChartData(pre => ({...pre,series:[{data:series.map(item=>item?.percentage)}],options:{...pre.options,xaxis:{...pre.options.xaxis,categories:series.map(item=>item?.country )}}}));
    
  
  }, [countryContainerList])

  return (
    <div id="chart">
      { countryContainerList?.length ? <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={280} /> : <div >No Country Wise Container Data Found For Selected Date</div>}
    </div>
  );
};

export default CountryChart;