import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactMapGL, {
  Marker,
  Popup,
  NavigationControl,
  GeolocateControl,
  Source,
  Layer,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";
import { InfoDiv } from "./InfoDiv";

export default function Map({ dashboardRawData }) {
  const [selectedMarker, setSelectedMarker] = useState(undefined);
  const [path, setPath] = useState([]);
  const mapRef = useRef();
  const popupRef = useRef();
  const openInfoWindow = (marker, e) => {
    // if(selectedMarker?.booking_id == marker?.booking_id){
    let path = [
      [
        parseFloat(
          parseJson(marker?.port_of_loading_location)?.coordinates?.longitude
        ),
        parseFloat(
          parseJson(marker?.port_of_loading_location)?.coordinates?.latitude
        ),
      ],
      [parseFloat(marker?.long), parseFloat(marker?.lat)],
      [
        parseFloat(
          parseJson(marker?.port_of_discharge_location)?.coordinates?.longitude
        ),
        parseFloat(
          parseJson(marker?.port_of_discharge_location)?.coordinates?.latitude
        ),
      ],
    ].filter((coord) => typeof coord[0] == 'number' && typeof coord[1] == 'number'); 
    setPath(path);
  setSelectedMarker(marker)
    // }
    if (mapRef.current && path.length > 0) {
      const bounds = path.reduce(
        (acc, coord) => acc.extend(coord),
        new mapboxgl.LngLatBounds(path[0], path[0])
      );
      mapRef.current.fitBounds(bounds, { padding: 50 });
    }
  };

  const closeInfoWindow = (e, marker) => {
    // if (selectedMarker?.booking_id == marker?.booking_id) {
      console.log(e);
      // e.target?.remove()
      setSelectedMarker(null);
    // }
  };

  function parseJson(json) {
    if (json) {
      return JSON.parse(json);
    } else {
      return null;
    }
  }

 

  return (
    <ReactMapGL
      ref={mapRef}
      style={{ width: "100%", height: "90vh", borderRadius: "10px" }}
      mapStyle="mapbox://styles/mapbox/streets-v11"
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
      initialViewState={{
        latitude: 0,
        longitude: 0,
        zoom: 1,
      }}
      dragPan
      scrollZoom={false}
      interactiveLayerIds={["marker-layer"]}
    >
      {dashboardRawData?.map((marker) => {
        if (marker.status_name !== "Delivered" && marker.lat && marker.long) {
          return (
            <Marker
              key={marker.booking_id}
              latitude={parseFloat(marker.lat)}
              longitude={parseFloat(marker.long)}
              color="red"
              // onClick={(e) => openInfoWindow(marker, e)}
              // popup={new mapboxgl.Popup({
              //   className: "popup",
              //   closeButton: false,
              // })
              //   .setHTML(renderToString(<InfoDiv marker={marker} />))
              //   .on("close", (e) => closeInfoWindow(e, marker))}
            >
              <svg onMouseOver={(e) => openInfoWindow(marker, e)} className="icon fs-30 mb-4 text-danger ">
                      <use href="#icon_location"></use>
                    </svg>
            </Marker>
          );
        }
        return null;
      })}

      {
      selectedMarker && 
      <Popup offset={[0,-20]} focusAfterOpen={true} ref={popupRef} key={selectedMarker?.booking_id} closeOnClick={false}   onClose={closeInfoWindow} latitude={selectedMarker.lat} longitude={selectedMarker.long} >
        <InfoDiv marker={selectedMarker} />
      </Popup>}

      {/* Draw line using Source and Layer */}
      {selectedMarker && (
        <Source
          type="geojson"
          data={{
            type: "Feature",
            geometry: {
              type: "LineString",
              coordinates: path,
            },
          }}
        >
          <Layer
            type="line"
            id="line-layer"
            paint={{
              "line-color": "red",
              "line-width": 4,
              "line-dasharray": [2, 2],
            }}
          />
        </Source>
      )}

      {selectedMarker && (
        <Marker
          latitude={
            parseJson(selectedMarker?.port_of_loading_location)?.coordinates
              ?.latitude
          }
          longitude={
            parseJson(selectedMarker?.port_of_loading_location)?.coordinates
              ?.longitude
          }
          color="yellow"
        > <svg className="icon fs-30 mb-4 text-warning ">
        <use href="#icon_location"></use>
      </svg></Marker>
      )}
      {selectedMarker && (
        <Marker
          latitude={
            parseJson(selectedMarker?.port_of_discharge_location)?.coordinates
              ?.latitude
          }
          longitude={
            parseJson(selectedMarker?.port_of_discharge_location)?.coordinates
              ?.longitude
          }
          color="green"
        ><svg className="icon fs-30 mb-4 text-success ">
        <use href="#icon_location"></use>
      </svg></Marker>
      )}

      {/* Add map controls */}
      <NavigationControl showCompass={false} position="bottom-right" />
      <GeolocateControl position="bottom-right" />
    </ReactMapGL>
  );
}
