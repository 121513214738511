import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const ApexChart = ({ statusPieList }) => {
  const [series, setSeries] = useState([]);
  const options = {
    chart: {
      type: "donut",
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
    legend: {
      position: "right",
      offsetY: 0,
      markers: {
        shape: "square", // Set the shape to 'square'
      },
    },
    // labels: ["Port of Discharge 12%", "In Transit 18%", "Port of Loading 14%", "At Customs 10%", "On Way To Warehouse 15% ", "Delivered  31%"],
    labels: series.map((item) => item?.status_name + " " + item?.percentage ),
  };

  useEffect(() => {
    
     
      const series = statusPieList?.map(item=> {
        const percentage =
        item?.total_container ;
      return {
        status_name: item?.status_name,
        percentage: percentage,
      }
      })
      setSeries(series);
   
  }, [statusPieList]);
  return (
    <>
      <div className="chart-wrap">
        <div id="chart">
          {statusPieList?.length ? <ReactApexChart
            className="pie-chart"
            options={options}
            series={series?.map(item => item?.percentage)}
            type="donut"
            height={280}
            
          /> : <div>No Status Data Found For Selected Date</div>}
        </div>
      </div>
    </>
  );
};
export default ApexChart;
