import {React,useState, useRef} from 'react'
import {Form, Link} from "react-router-dom";
import {Container, Row, Col} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import CryptoJS from "crypto-js";
import axios from "axios";
import Loader from "../components/Loader";

function Login() {
  const [loginDetails, setloginDetails] = useState({
    email: "",
    password: "",
    eyeShown: false,
  });
  const [error, seterror] = useState({
    status: false,
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showPass, setShowPass] = useState(false)
  const navigate = useNavigate();
  const emailInputRef = useRef();
  const passInputRef = useRef();

  async function handleLogin(e) {
    seterror({
      status: false,
      message: "",
    });
    e.preventDefault();
    setIsLoading(true);
    const email = emailInputRef.current.value;
    const pass = passInputRef.current.value;
    try {
      const cipherText = CryptoJS.AES.encrypt(
        pass,
        process.env.REACT_APP_SECRET
      );
      const data = {
        email: email,
        password: cipherText.toString(),
        // app_type: "operation",
      };
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/mtmuser/login`,
        data
      );
      if (response.status == 200) {
        setIsLoading(false);
        if (response.data.success) {
          localStorage.setItem("mtm_user_token", response?.data?.token);
          localStorage.setItem(
            "mtm_user_data",
            JSON.stringify(response?.data?.data)
          );
          navigate("/", { replace: true });
        }
      } else {
        setIsLoading(false);
        alert("Error");
        console.log("Error");
      }
    } catch (error) {
      seterror({
        status: true,
        message: error?.response?.data?.msg,
      });
      setIsLoading(false);
      console.log(error);
    }
  } 
  return (
    <>
    <div className='signin-box d-flex align-items-center'>
      <img className='loginfram' src='images/frame.png'/>
      <Row className='w-100'>
        <Col sm={6} className="signin-left">
          <div className='d-flex justify-content-center pb-3'>
            <img className='' src='images/logo.png'/>
          </div>
          <div className='d-flex justify-content-center'>
            <img className='group' src='images/group.png'/>
          </div>
        </Col>
        <Col sm={6} className=''>
          <h2 className='fw-700 fs-35 pt-4 text-center text-blue'>Log In</h2>
          <form className='my-5' onSubmit={handleLogin}>
            <div className="mb-3">
              <div class="inputWithIcon">
                <input type="email"
                ref={emailInputRef} 
                className='login-input' placeholder="Input your user ID or Email" required/>
                <i aria-hidden="true">
                  <svg className="icon fs-24">
                    <use href="#icon_email"></use>
                  </svg>
                </i>
              </div>
            </div>
            <div className="mb-3">
              <div class="inputWithIcon position-relative">
                <input type={showPass ? "text":"password"}
                ref={passInputRef} className='login-input' placeholder="Input your password" required/>
                 <button
                    type="button"
                    className="vision"
                    onClick={() =>
                     setShowPass(!showPass)
                    }
                  >
                    {showPass ? (
                      <svg className="icon">
                        <use href="#icon_vision_withoutslash"></use>
                      </svg>
                    ) : (
                      <svg className="icon">
                        <use href="#icon_vision"></use>
                      </svg>
                    )}
                  </button>
                <i aria-hidden="true">
                  <svg className="icon fs-24">
                    <use href="#icon_key"></use>
                  </svg>
                </i>
              </div>
            </div>
          <div className="text-center text-danger fw-600 fs-20">
            {error.status && <>{error.message}</>}
          </div>
          
          <button type='submit' className='my-5 grd-btn fs-20 fw-600 w-100 d-flex text-center align-items-center'>
            {isLoading ? <Loader /> 
            :<>
            <svg className="icon fs-20 me-2">
              <use href="#icon_login"></use>
            </svg>
            Login
            </>  }
          </button>
          </form>
        </Col>
      </Row>
    </div>
    </>
  )
}

export default Login