import React, { useEffect, useState } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
// import { convert } from "currency-exchanger-js";
import {
  conditionalExp,
  getDemandFactor,
  // getPODPorts,
  // getPOLPorts,
  getSKUListFootprint,
  convert,
  currencyOptions
} from "../utils/commonFn";
import axios from "axios";
import Loader from "../components/Loader";
import ErrorModal from "../components/ErrorModal";
import { DatePicker } from "antd";

function SkuFootprint() {
  const mtm_user_data = JSON.parse(localStorage.getItem("mtm_user_data"));
  const [isLoadingAll, setIsLoadingAll] = useState(false);
  const [pol, setPol] = useState();
  const [pod, setPod] = useState();
  const [polCountry, setPolCountry] = useState();
  const [podCountry, setPodCountry] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [optionsForPortsOrigin, setOptionsForPortsOrigin] = useState([]);
  const [optionsForCountries, setOptionsForCountries] = useState([]);
  const [optionsForPortsDestination, setOptionsForPortsDestination] = useState(
    []
  );
  const [airportCodes, setAirportCodes] = useState([]);
  const [selectedContainer, setSelectedContainer] = useState();
  const [selectedCBM, setSelectedCBM] = useState();
  const [cbmList, setCbmList] = useState([]);
  const [containersList, setContainersList] = useState([]);
  const [allDropdowns, setallDropdowns] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedModel, setSelectedModel] = useState();
  const [selectedDepartureDate, setSelectedDepartureDate] = useState();
  const [allModals, setallModals] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [skuListDetailsRaw, setSkuListDetailsRaw] = useState([]);
  const [tradeType, setTradeType] = useState();
  const [selectedCurrency, setselectedCurrency] = useState({
    label: "USD-US Dollar",
    value: "USD",
  });
  const [rows, setRows] = useState([
    {
      item_code: null,
      buying_qty: 0,
      unit_cost: 0,
      logistics_unit_cost: 0,
      tax_per_unit: 0,
      final_unit_cost: 0,
      volume_cover: 0,
      total_buying_cost: 0,
      total_tax: 0,
      tax_code: "",
      isChanged: false,
      container_cost: 0,
      total_container: 0,
      volum_used_cmb: 0,
      unit_cost_usd:0,
      logistics_unit_cost_usd:0,
      total_buying_cost_usd:0,
      tax_per_unit_usd:0,
      total_tax_usd:0,
      final_unit_cost_usd:0,
      container_cost_usd:0,
    },
  ]);
  const [showErrModal, setShowErrModal] = useState(false);
  const [error, setError] = useState();
  const [demandFactor, setDemandFactor] = useState();

  const navigate = useNavigate();

  async function getSkuList() {
    try {
      setIsLoadingAll(true);
      let token = localStorage.getItem("mtm_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmdemandforcast/demandforcast_list`,
        method: "POST",
        data: {
          customer_id: mtm_user_data?.customer_id,
          page: 0,
          searchtxt: "",
          year: demandFactor ? demandFactor?.format("YYYY") : null,
          demand_forcast_type: mtm_user_data?.demand_forcast_type,
          demand_factor: getDemandFactor(demandFactor, mtm_user_data),
        },
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
      });

      if (!response.data.error) {
        let newRows = response.data?.result?.map((item) => {
          return {
            item_code: {
              label: item?.item_code + " | " + item?.item_name,
              value: item?.item_code,
            },
            buying_qty: item?.demand_qty || 0,
            unit_cost: 0,
            logistics_unit_cost: 0,
            tax_per_unit: 0,
            final_unit_cost: 0,
            volume_cover: 0,
            total_buying_cost: 0,
            total_tax: 0,
            tax_code: "",
            isChanged: false,
            container_cost: 0,
            total_container: 0,
            volum_used_cmb: 0,
            unit_cost_usd:0,
            logistics_unit_cost_usd:0,
            total_buying_cost_usd:0,
            tax_per_unit_usd:0,
            total_tax_usd:0,
            final_unit_cost_usd:0,
            container_cost_usd:0,
            pickup_address: item?.origin,
            delivery_address: item?.warehouse_code,
            unit_cbm: item?.unit_cbm,
          };
        });
        setRows(newRows || []);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setIsLoadingAll(false);
    }
  }

  function checkFnForSubmit(row) {
    if (!row?.item_code?.value) {
      return "Please select sku.";
    } else if (
      (selectedProduct?.label?.toLowerCase()?.includes("ocean fcl") ||
        selectedProduct?.label?.toLowerCase()?.includes("truck ftl")) &&
      !selectedContainer
    ) {
      return "Please select container size.";
    } else if (
      !(
        selectedProduct?.label?.toLowerCase()?.includes("ocean fcl") ||
        selectedProduct?.label?.toLowerCase()?.includes("truck ftl")
      ) &&
      !selectedCBM
    ) {
      return "Please select CBM.";
    } else if (
      !selectedProduct?.label?.toLowerCase()?.includes("truck") &&
      !mtm_user_data?.demand_forcast_type
    ) {
      if (!pol) {
        return "Please select port of loading.";
      } else if (!pod) {
        return "Please select port of discharge.";
      }
    } else if (row?.buying_qty == "" || row?.buying_qty == 0) {
      return "Please enter buying quantity .";
    } else if (!tradeType?.value) {
      return "Please select trade type.";
    } else if (mtm_user_data?.demand_forcast_type && !demandFactor) {
      return `Please select demand factor ${mtm_user_data?.demand_forcast_type?.replace(
        "ly",
        ""
      )}.`;
    }
  }

  const getAirPort = async () => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmcostcalculation/country/airport_get`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("mtm_user_token"),
        },
      });

      if (!response.data?.error) {
        setAirportCodes(response.data?.port_details || []);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      console.log(error);
    }
  };

  const handleSubmit = async (row, index) => {
    setIsLoading(true);
    if (!mtm_user_data?.demand_forcast_type) {
      let error = checkFnForSubmit(row);
      if (error) {
        setError({ title: error });
        setShowErrModal(true);
        setIsLoading(false);
        return;
      }
    } else {
      let error = rows?.map((item) => checkFnForSubmit(item));
      console.log(error.every((item) => item));
      if (error.every((item) => item)) {
        setError({ title: error?.filter((item) => item)?.join(", ") });
        setShowErrModal(true);
        setIsLoading(false);
        return;
      }
    }
    try {
      let token = localStorage.getItem("mtm_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmcostcalculation/itemcost/calculation`,
        method: "POST",
        data: mtm_user_data?.demand_forcast_type
          ? {
              year: demandFactor ? demandFactor?.format("YYYY") : null,
              demand_factor: getDemandFactor(demandFactor, mtm_user_data),
              product_type: selectedProduct?.value,
              product_type_name: selectedProduct?.label,
              service_type: selectedModel?.value,
              trade_type: tradeType?.value,
              customer_id: JSON.parse(localStorage.getItem("mtm_user_data"))
                ?.customer_id,
              container_size: selectedContainer?.container_size,
              container_type: selectedContainer?.value,
              cbm: selectedCBM?.value,
              origin_country: polCountry?.label,
              destination_country: podCountry?.label,
              demand_forcast_type: mtm_user_data?.demand_forcast_type,
              items: row?.map((item) => ({
                item_code: item?.item_code?.value,
                qty: item?.buying_qty,
                unit_cbm: item?.unit_cbm,
                origin: item?.pickup_address,
                warehouse_code: item?.delivery_address,
              })),
              covered_container_size: conditionalExp(
                row?.length == 1,
                rows?.reduce(
                  (a, b) => a + parseFloat(b.volum_used_cmb || 0),
                  0
                ) - (row[0]?.volum_used_cmb || 0),
                0
              ),
            }
          : {
              demand_forcast_type: null,
              item_code: row?.item_code?.value,
              qty: row?.buying_qty,
              container_type: selectedContainer?.value,
              pol: pol?.value,
              pod: pod?.value,
              product_type: selectedProduct?.value,
              product_type_name: selectedProduct?.label,
              service_type: selectedModel?.value,
              departure_date: selectedDepartureDate?.format("YYYY-MM-DD"),
              origin_country: polCountry?.label,
              destination_country: podCountry?.label,
              customer_id: JSON.parse(localStorage.getItem("mtm_user_data"))
                ?.customer_id,
              trade_type: tradeType?.value,
              cbm: selectedCBM?.value,
              container_size: selectedContainer?.container_size,
              covered_container_size:
                rows?.reduce(
                  (a, b) => a + parseFloat(b.volum_used_cmb || 0),
                  0
                ) - (row?.volum_used_cmb || 0),
            },
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
      });

      if (!mtm_user_data?.demand_forcast_type) {
        if (!response.data?.error) {
          let respData = response.data;

          if (!respData?.container_cost) {
            setError({ title: "Price not available for this sku." });
            setShowErrModal(true);
            return;
          }
          respData = {
            ...respData,
            unit_cost_usd:respData?.unit_cost,
            logistic_unit_cost_usd:respData?.logistic_unit_cost,
            total_buy_cost_usd:respData?.total_buy_cost,
            tax_per_unit_usd:respData?.tax_per_unit,
            total_tax_usd:respData?.total_tax,
            final_cost_per_bottale_usd:respData?.final_cost_per_bottale,
            container_cost_usd:respData?.container_cost,
          }
          if (selectedCurrency?.value != "USD") {
            respData = {
              ...respData,
              unit_cost: await convert(
                parseFloat(respData?.unit_cost || 0),
                "USD",
                selectedCurrency?.value
              ),
              logistic_unit_cost: await convert(
                parseFloat(respData?.logistic_unit_cost || 0),
                "USD",
                selectedCurrency?.value
              ),
              total_buy_cost: await convert(
                parseFloat(respData?.total_buy_cost || 0),
                "USD",
                selectedCurrency?.value
              ),
              tax_per_unit: await convert(
                parseFloat(respData?.tax_per_unit || 0),
                "USD",
                selectedCurrency?.value
              ),
              total_tax: await convert(
                parseFloat(respData?.total_tax || 0),
                "USD",
                selectedCurrency?.value
              ),
              final_cost_per_bottale: await convert(
                parseFloat(respData?.final_cost_per_bottale || 0),
                "USD",
                selectedCurrency?.value
              ),
              container_cost: await convert(
                parseFloat(respData?.container_cost || 0),
                "USD",
                selectedCurrency?.value
              ),
            };
            console.log(respData);
          }

          setRows((pre) => {
            let update = [...pre];
            update[index] = {
              ...update[index],
              isChanged: false,
              unit_cost: parseFloat(respData?.unit_cost).toFixed(2),
              logistics_unit_cost: parseFloat(
                respData?.logistic_unit_cost
              ).toFixed(2),
              volume_cover: respData?.volum_used_percent,
              total_buying_cost: parseFloat(respData?.total_buy_cost).toFixed(
                2
              ),
              tax_per_unit: parseFloat(respData?.tax_per_unit).toFixed(2),
              total_tax: parseFloat(respData?.total_tax).toFixed(2),
              tax_code: respData?.tax_code,
              final_unit_cost: parseFloat(
                respData?.final_cost_per_bottale
              ).toFixed(2),
              container_cost: parseFloat(respData?.container_cost).toFixed(2),
              volum_used_cmb: respData?.volum_used_cmb,
              total_required_pallet: respData?.total_required_pallet,
              gross_weight_kg: respData?.gross_weight_kg,
              total_container: respData?.total_container,
              //initial usd cost
              unit_cost_usd: respData?.unit_cost_usd,
              logistics_unit_cost_usd: respData?.logistic_unit_cost_usd,
              total_buying_cost_usd: respData?.total_buy_cost_usd,
              tax_per_unit_usd: respData?.tax_per_unit_usd,
              total_tax_usd: respData?.total_tax_usd,
              final_unit_cost_usd: respData?.final_cost_per_bottale_usd,
              container_cost_usd: respData?.container_cost_usd,
            };
            return [...update];
          });
        } else {
          setRows((pre) => {
            let update = [...pre];
            update[index] = {
              ...update[index],
              isChanged: false,
            };
            return [...update];
          });

          setError({ title: response?.data?.message });
          setShowErrModal(true);
        }
      } else {
        if (!response.data?.error) {
          let respData = response.data?.itemskus;

          if (!respData?.every((item) => item?.container_cost)) {
            setError({
              title: "Price not available for these skus.",
              err: respData
                ?.filter((item) => !item?.container_cost)
                ?.map((item) => item?.item_code)
                ?.join(", "),
            });
            setShowErrModal(true);
          }
          respData = respData?.map((item) => ({...item,
            unit_cost_usd:item?.unit_cost,
            logistic_unit_cost_usd:item?.logistic_unit_cost,
            total_buy_cost_usd:item?.total_buy_cost,
            tax_per_unit_usd:item?.tax_per_unit,
            total_tax_usd:item?.total_tax,
            final_cost_per_bottale_usd:item?.final_cost_per_bottale,
            container_cost_usd:item?.container_cost,
          }));
          if (selectedCurrency?.value != "USD") {
            respData = await Promise.all(
              await respData?.map(async (item) => {
                return {
                  ...item,
                  unit_cost: await convert(
                    parseFloat(item?.unit_cost || 0),
                    "USD",
                    selectedCurrency?.value
                  ),
                  logistic_unit_cost: await convert(
                    parseFloat(item?.logistic_unit_cost || 0),
                    "USD",
                    selectedCurrency?.value
                  ),
                  total_buy_cost: await convert(
                    parseFloat(item?.total_buy_cost || 0),
                    "USD",
                    selectedCurrency?.value
                  ),
                  tax_per_unit: await convert(
                    parseFloat(item?.tax_per_unit || 0),
                    "USD",
                    selectedCurrency?.value
                  ),
                  total_tax: await convert(
                    parseFloat(item?.total_tax || 0),
                    "USD",
                    selectedCurrency?.value
                  ),
                  final_cost_per_bottale: await convert(
                    parseFloat(item?.final_cost_per_bottale || 0),
                    "USD",
                    selectedCurrency?.value
                  ),
                  container_cost: await convert(
                    parseFloat(item?.container_cost || 0),
                    "USD",
                    selectedCurrency?.value
                  ),
                };
              })
            );
          }

          if (index != null || index != undefined) {
            setRows((pre) => {
              let update = [...pre];
              update[index] = {
                ...update[index],
                isChanged: false,
                unit_cost: parseFloat(respData[0]?.unit_cost).toFixed(2),
                logistics_unit_cost: parseFloat(
                  respData[0]?.logistic_unit_cost
                ).toFixed(2),
                volume_cover: respData[0]?.volum_used_percent,
                total_buying_cost: parseFloat(
                  respData[0]?.total_buy_cost
                ).toFixed(2),
                tax_per_unit: parseFloat(respData[0]?.tax_per_unit).toFixed(2),
                total_tax: parseFloat(respData[0]?.total_tax).toFixed(2),
                tax_code: respData[0]?.tax_code,
                final_unit_cost: parseFloat(
                  respData[0]?.final_cost_per_bottale
                ).toFixed(2),
                container_cost: parseFloat(respData[0]?.container_cost).toFixed(
                  2
                ),
                volum_used_cmb: respData[0]?.volum_used_cmb,
                total_required_pallet: respData[0]?.total_required_pallet,
                gross_weight_kg: respData[0]?.gross_weight_kg,
                total_container: respData[0]?.total_container,
                //initial usd cost
              unit_cost_usd: respData[0]?.unit_cost_usd,
              logistics_unit_cost_usd: respData[0]?.logistic_unit_cost_usd,
              total_buying_cost_usd: respData[0]?.total_buy_cost_usd,
              tax_per_unit_usd: respData[0]?.tax_per_unit_usd,
              total_tax_usd: respData[0]?.total_tax_usd,
              final_unit_cost_usd: respData[0]?.final_cost_per_bottale_usd,
              container_cost_usd: respData[0]?.container_cost_usd,
              };
              return [...update];
            });
          } else {
            let updatedRows = respData?.map((item, ind) => {
              return {
                ...rows[ind],
                isChanged: false,
                unit_cost: parseFloat(item?.unit_cost).toFixed(2),
                logistics_unit_cost: parseFloat(
                  item?.logistic_unit_cost
                ).toFixed(2),
                volume_cover: item?.volum_used_percent,
                total_buying_cost: parseFloat(item?.total_buy_cost).toFixed(2),
                tax_per_unit: parseFloat(item?.tax_per_unit).toFixed(2),
                total_tax: parseFloat(item?.total_tax).toFixed(2),
                tax_code: item?.tax_code,
                final_unit_cost: parseFloat(
                  item?.final_cost_per_bottale
                ).toFixed(2),
                container_cost: parseFloat(item?.container_cost).toFixed(2),
                volum_used_cmb: item?.volum_used_cmb,
                total_required_pallet: item?.total_required_pallet,
                gross_weight_kg: item?.gross_weight_kg,
                total_container: item?.total_container,
                //initial usd cost
              unit_cost_usd: item?.unit_cost_usd,
              logistics_unit_cost_usd: item?.logistic_unit_cost_usd,
              total_buying_cost_usd: item?.total_buy_cost_usd,
              tax_per_unit_usd: item?.tax_per_unit_usd,
              total_tax_usd: item?.total_tax_usd,
              final_unit_cost_usd: item?.final_cost_per_bottale_usd,
              container_cost_usd: item?.container_cost_usd,
              };
            });

            setRows(updatedRows);
          }
        } else {
          setError({ title: response?.data?.message });
          setShowErrModal(true);
        }
      }
    } catch (error) {
      console.log(error);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  async function getDropdown() {
    try {
      const token = localStorage.getItem("mtm_user_token");
      let clickup_data = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/mtmcostcalculation/quote/dropdowns`,
        {
          headers: {
            "Content-Type": "application/json",
            jwt_token: token,
          },
        }
      );
      setAllProducts(
        clickup_data?.data?.dropdown_details
          ?.filter((item) => item?.dropdown_type == "product")
          ?.slice(0, -3)
          ?.map((item) => ({
            label: item?.clickup_product_name,
            value: item?.clickup_product_id,
            product_id: item?.product_id,
          }))
      );
      setallDropdowns(clickup_data?.data?.dropdown_details || []);
      setContainersList(
        clickup_data?.data?.dropdown_details
          ?.filter((item) => {
            return (
              item?.dropdown_type == "container_type" && item?.container_size
            );
          })
          ?.map((item) => ({
            label: item?.clickup_product_name,
            value: item?.container_type,
            container_size: item?.container_size,
          }))
      );
    } catch (err) {
      if (err?.response?.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      console.log(err);
    }
  }
  async function getModals() {
    try {
      const token = localStorage.getItem("mtm_user_token");
      let clickup_data = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/mtmcostcalculation/quote/dropdowns/service_type`,
        {
          product_id: selectedProduct?.product_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            jwt_token: token,
          },
        }
      );
      setallModals(
        clickup_data?.data?.dropdown_details?.map((item) => ({
          label: item?.modal_name,
          value: item?.modal_id,
        }))
      );
    } catch (err) {
      if (err?.response?.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      console.log(err);
    }
  }
  async function getCBMList() {
    try {
      const token = localStorage.getItem("mtm_user_token");
      let clickup_data = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/mtmcostcalculation/quote/get_cbm_list`,

        {
          headers: {
            "Content-Type": "application/json",
            jwt_token: token,
          },
        }
      );
      setCbmList(
        clickup_data?.data?.cbm_list?.map((item) => ({
          label: item?.cbm,
          value: item?.cbm,
        }))
      );
    } catch (err) {
      if (err?.response?.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      console.log(err);
    }
  }
  async function getCountries() {
    try {
      const token = localStorage.getItem("mtm_user_token");
      let clickup_data = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/mtmcostcalculation/country/list`,
        {
          headers: {
            "Content-Type": "application/json",
            jwt_token: token,
          },
        }
      );
      setOptionsForCountries(
        clickup_data?.data?.country?.map((item) => ({
          label: item?.country,
          value: item?.country_sort_code,
        }))
      );
    } catch (err) {
      if (err?.response?.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      console.log(err);
    }
  }
  async function getPorts(country_code, country_name) {
    try {
      if (selectedProduct?.label?.toLowerCase() == "air") {
        const countryAirports = airportCodes?.filter(
          (ap) =>
            ap?.country?.toLowerCase() == country_name?.toLowerCase() &&
            ap?.iata
        );
        // console.log('country airports',countryAirports);
        // Map the airport data to the format required by react-select options
        const mappedAirports = countryAirports.map((airport) => ({
          value: `${airport.name} | ${airport.iata}`,
          label: `${airport.name} | ${airport.iata}`,
        }));
        return mappedAirports;
      }
      const token = localStorage.getItem("mtm_user_token");
      let clickup_data = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/mtmcostcalculation/country/port/search`,
        {
          country_sort_code: country_code,
        },
        {
          headers: {
            "Content-Type": "application/json",
            jwt_token: token,
          },
        }
      );
      return clickup_data?.data?.port_details?.map((item) => ({
        label: item?.port_name + " | " + item?.global_port_code,
        value: item?.global_port_code,
      }));
    } catch (err) {
      if (err?.response?.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      console.log(err);
    }
  }

  useEffect(() => {
    if (selectedProduct?.product_id) {
      setSelectedModel(null);
      setPol(null);
      setPod(null);
      setPolCountry(null);
      setPodCountry(null);
      setSelectedContainer(null);
      setSelectedDepartureDate(null);
      setTradeType(null);
      setSelectedCBM(null);
      setSelectedContainer(null);
      getModals();
    }
  }, [selectedProduct]);

  useEffect(() => {
    // getPOL_PODPorts(
    //   setOptionsForPortsOrigin,
    //   setOptionsForPortsDestination,
    //   navigate
    // );
    getDropdown();
    getCountries();
    getAirPort();
    getCBMList();
    getSKUListFootprint(setSkuListDetailsRaw, navigate);
  }, []);

  useEffect(() => {
    if (selectedProduct?.label) {
      if (selectedProduct?.label?.toLowerCase()?.includes("ocean fcl")) {
        setContainersList(
          allDropdowns
            ?.filter((item) => {
              return (
                item?.dropdown_type == "container_type" && item?.container_size
              );
            })
            ?.map((item) => ({
              label: item?.clickup_product_name,
              value: item?.container_type,
              container_size: item?.container_size,
            }))
        );
      } else {
        setContainersList(
          allDropdowns
            ?.filter((item) => {
              return (
                (item?.dropdown_type == "truck_container_type_both" ||
                  item?.dropdown_type == "truck_container_type_domestic" ||
                  item?.dropdown_type ==
                    "truck_container_type_international") &&
                item?.container_size
              );
            })
            ?.map((item) => ({
              label: item?.clickup_product_name,
              value: item?.container_type,
              container_size: item?.container_size,
            }))
        );
      }
    }
  }, [selectedProduct]);

  // useEffect(() => {
  //   if (selectedContainer?.value) {
  //     setPol(null);
  //     setPod(null);
  //     setOptionsForPortsDestination([]);
  //     getPOLPorts(setOptionsForPortsOrigin, selectedContainer?.value, navigate);
  //   }
  // }, [selectedContainer]);

  // useEffect(() => {
  //   if (pol?.value && selectedContainer?.value) {
  //     setPod(null);
  //     getPODPorts(
  //       setOptionsForPortsDestination,
  //       selectedContainer?.value,
  //       pol?.value,
  //       navigate
  //     );
  //   }
  // }, [pol]);

  useEffect(() => {
    if (mtm_user_data?.demand_forcast_type && demandFactor) {
      getSkuList();
    }
  }, [demandFactor]);

  async function handleCurrencyChange(option) {
    console.log(selectedCurrency?.value,option?.value);
    let modifiedRows = [];
    for (const sku of rows) {
      modifiedRows.push({
        ...sku,
        container_cost: parseFloat(
          await convert(
            parseFloat(sku?.container_cost_usd || 0),
            // selectedCurrency?.value,
              'USD',
            option?.value
          )
        ).toFixed(2),
        final_unit_cost: parseFloat(
          await convert(
            parseFloat(sku?.final_unit_cost_usd || 0),
            // selectedCurrency?.value,
              'USD',
            option?.value
          )
        ).toFixed(2),
        logistics_unit_cost: parseFloat(
          await convert(
            parseFloat(sku?.logistics_unit_cost_usd || 0),
            // selectedCurrency?.value,
              'USD',
            option?.value
          )
        ).toFixed(2),
        tax_per_unit: parseFloat(
          await convert(
            parseFloat(sku?.tax_per_unit_usd || 0),
            // selectedCurrency?.value,
              'USD',
            option?.value
          )
        ).toFixed(2),
        total_buying_cost: parseFloat(
          await convert(
            parseFloat(sku?.total_buying_cost_usd || 0),
            // selectedCurrency?.value,
              'USD',
            option?.value
          )
        ).toFixed(2),
        total_tax: parseFloat(
          await convert(
            parseFloat(sku?.total_tax_usd || 0),
            // selectedCurrency?.value,
              'USD',
            option?.value
          )
        ).toFixed(2),
        unit_cost: parseFloat(
          await convert(
            parseFloat(sku?.unit_cost_usd || 0),
            // selectedCurrency?.value,
              'USD',
            option?.value
          )
        ).toFixed(2),
      });
    }

    setRows(modifiedRows);
    setselectedCurrency(option);
  }
  return (
    <>
      <Container style={{ minWidth: "100%" }}>
        <Row>
          <Col>
            <div className="wrapper-box">
              <div className="d-flex justify-content-end">
                <Select
                  value={selectedCurrency}
                  onChange={handleCurrencyChange}
                  options={currencyOptions}
                />
              </div>
              <h2 className="fw-700 flex-2 fs-30 text-center">
                SKU Cost Footprint ({selectedCurrency?.value})
              </h2>
              <div className="row row-cols-auto pt-4">
                <div className="col">
                  <p>Country Of Origin</p>
                  <h6 className="fw-700 fs-30">
                    {polCountry?.label || "Select Country"}
                  </h6>
                </div>
                <div className="col">
                  <p>Country Of Destination</p>
                  <h6 className="fw-700 fs-30">
                    {podCountry?.label || "Select Country"}
                  </h6>
                </div>
                <div className="col">
                  <p>Total Units</p>
                  <h6 className="fw-700 fs-30">
                    {rows?.reduce((a, b) => a + parseFloat(b.buying_qty), 0) ||
                      0}
                  </h6>
                </div>
                <div className="col">
                  <p>Total Cost</p>
                  <h6 className="fw-700 fs-30">
                    {parseFloat(
                      rows?.slice(-1)[0]?.container_cost || 0
                    ).toFixed(2)}
                  </h6>
                </div>
                <div className="col">
                  <p>Logistics Cost Per Unit</p>
                  <h6 className="fw-700 fs-30">
                    {parseFloat(
                      rows?.slice(-1)[0]?.container_cost /
                        rows?.reduce(
                          (a, b) => a + parseFloat(b.buying_qty),
                          0
                        ) || 0
                    ).toFixed(2)}
                  </h6>
                </div>
                {conditionalExp(
                  selectedProduct?.label
                    ?.toLowerCase()
                    ?.includes("ocean fcl") ||
                    selectedProduct?.label
                      ?.toLowerCase()
                      ?.includes("truck ftl"),
                  <>
                    <div className="col">
                      <p>Container Volume (CBM)</p>
                      <h6 className="fw-700 fs-30">
                        {conditionalExp(
                          selectedContainer?.value,
                          selectedContainer?.container_size,
                          "0"
                        )}
                      </h6>
                    </div>
                    <div className="col">
                      <p>Total Containers</p>
                      <h6 className="fw-700 fs-30">
                        {parseInt(rows?.slice(-1)[0]?.total_container || 0)}
                      </h6>
                    </div>
                  </>
                )}

                <div className="col">
                  <p>Covered Volume (CBM)</p>
                  <h6 className="fw-700 fs-30">
                    {parseFloat(
                      rows?.reduce(
                        (a, b) => a + parseFloat(b.volum_used_cmb || 0),
                        0
                      )
                    ).toFixed(5)}
                  </h6>
                </div>
                {!mtm_user_data?.demand_forcast_type && (
                  <div className="col">
                    <p>Gross Weight</p>
                    <h6 className="fw-700 fs-30">
                      {parseFloat(
                        rows?.reduce(
                          (a, b) => a + parseFloat(b?.gross_weight_kg || 0),
                          0
                        )
                      ).toFixed(2)}
                    </h6>
                  </div>
                )}
                {conditionalExp(
                  selectedProduct?.label?.toLowerCase()?.includes("ocean fcl"),
                  <div className="col">
                    <p>Volumetric Weight</p>
                    <h6 className="fw-700 fs-30">
                      {parseFloat(
                        conditionalExp(
                          selectedContainer?.value,
                          selectedContainer?.container_size,
                          0
                        ) *
                          1000 *
                          parseInt(rows?.slice(-1)[0]?.total_container || 0)
                      ).toFixed(2)}
                    </h6>
                  </div>
                )}
              </div>
              <div className="d-flex pt-4 pb-2">
                <div className="me-5">
                  <label
                    for="ContainerSize"
                    className="form-label fs-20 fw-600"
                  >
                    Choose Service
                  </label>
                  <Select
                    value={selectedProduct}
                    onChange={(option) => setSelectedProduct(option)}
                    options={allProducts}
                    isOptionDisabled={(option) =>
                      conditionalExp(
                        mtm_user_data?.demand_forcast_type,
                        false,
                        option?.label != "OCEAN FCL"
                      )
                    }
                    isLoading={!allProducts?.length}
                    isSearchable={true}
                    placeholder="Choose Service"
                  />
                </div>
                <div className="me-5">
                  <label for="ChoosePol" className="form-label fs-20 fw-600">
                    Choose Model
                  </label>

                  <Select
                    isDisabled={!selectedProduct?.value}
                    value={selectedModel}
                    onChange={(option) => setSelectedModel(option)}
                    options={allModals}
                    isSearchable={true}
                    placeholder="Choose Model"
                    isLoading={!allModals?.length}
                  />
                </div>
                {conditionalExp(
                  selectedProduct?.label
                    ?.toLowerCase()
                    ?.includes("ocean fcl") ||
                    selectedProduct?.label
                      ?.toLowerCase()
                      ?.includes("truck ftl"),
                  <div className="me-5">
                    <label
                      for="ContainerSize"
                      className="form-label fs-20 fw-600"
                    >
                      Container Size
                    </label>
                    <Select
                      value={selectedContainer}
                      onChange={(option) => setSelectedContainer(option)}
                      options={containersList}
                      isSearchable={true}
                      placeholder="Choose Container Size"
                    />
                  </div>,
                  <div className="me-5">
                    <label
                      for="ContainerSize"
                      className="form-label fs-20 fw-600"
                    >
                      CBM
                    </label>
                    <Select
                      value={selectedCBM}
                      onChange={(option) => setSelectedCBM(option)}
                      options={cbmList}
                      isSearchable={true}
                      placeholder="Choose CBM"
                    />
                  </div>
                )}
                {!mtm_user_data?.demand_forcast_type && (
                  <div>
                    <label for="ChoosePod" className="form-label fs-20 fw-600">
                      Departure Date
                    </label>

                    <DatePicker
                      value={selectedDepartureDate}
                      onChange={(date) => setSelectedDepartureDate(date)}
                      className="form-control"
                    />
                  </div>
                )}
              </div>
              <div className="d-flex pt-4 pb-2">
                <div className="me-5">
                  <label
                    for="ContainerSize"
                    className="form-label fs-20 fw-600"
                  >
                    Origin Country
                  </label>
                  <Select
                    value={polCountry}
                    onChange={async (option) => {
                      setPolCountry(option);
                      setPol(null);
                      setOptionsForPortsOrigin(
                        await getPorts(option?.value, option?.label)
                      );
                    }}
                    options={optionsForCountries}
                    isLoading={!optionsForCountries?.length}
                    isSearchable={true}
                    placeholder="Choose Country"
                  />
                </div>
                {!selectedProduct?.label?.toLowerCase()?.includes("truck") &&
                  !mtm_user_data?.demand_forcast_type && (
                    <div className="me-5">
                      <label
                        for="ChoosePol"
                        className="form-label fs-20 fw-600"
                      >
                        Origin Port
                      </label>

                      <Select
                        isDisabled={!polCountry?.value}
                        value={pol}
                        onChange={(option) => setPol(option)}
                        options={optionsForPortsOrigin}
                        isSearchable={true}
                        placeholder="Choose POL"
                        isLoading={!optionsForPortsOrigin?.length}
                      />
                    </div>
                  )}
                <div className="me-5">
                  <label
                    for="ContainerSize"
                    className="form-label fs-20 fw-600"
                  >
                    Destination Country
                  </label>
                  <Select
                    value={podCountry}
                    onChange={async (option) => {
                      setPodCountry(option);
                      setPod(null);
                      setOptionsForPortsDestination(
                        await getPorts(option?.value, option?.label)
                      );
                    }}
                    options={optionsForCountries}
                    isLoading={!optionsForCountries?.length}
                    isSearchable={true}
                    placeholder="Choose Country"
                  />
                </div>
                {!selectedProduct?.label?.toLowerCase()?.includes("truck") &&
                  !mtm_user_data?.demand_forcast_type && (
                    <div className="me-5">
                      <label
                        for="ChoosePod"
                        className="form-label fs-20 fw-600"
                      >
                        Destination Port
                      </label>

                      <Select
                        isDisabled={!podCountry?.value}
                        value={pod}
                        onChange={(option) => setPod(option)}
                        options={optionsForPortsDestination}
                        isSearchable={true}
                        placeholder="Choose POD"
                        isLoading={!optionsForPortsDestination?.length}
                      />
                    </div>
                  )}
                <div>
                  <label for="ChooseTT" className="form-label fs-20 fw-600">
                    Import/Export
                  </label>

                  <Select
                    value={tradeType}
                    onChange={(option) => setTradeType(option)}
                    options={[
                      {
                        value: "import",
                        label: "Import",
                      },
                      {
                        label: "Export",
                        value: "export",
                      },
                    ]}
                    placeholder="Choose Trade Type"
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div
                  style={{
                    visibility: conditionalExp(
                      mtm_user_data?.demand_forcast_type,
                      "visible",
                      "hidden"
                    ),
                  }}
                  className="d-flex mt-2"
                >
                  <DatePicker
                    popupClassName="datepicker"
                    value={demandFactor}
                    onChange={setDemandFactor}
                    picker={conditionalExp(
                      mtm_user_data?.demand_forcast_type,
                      mtm_user_data?.demand_forcast_type?.replace("ly", ""),
                      "weekly"
                    )}
                    className="me-3"
                  />
                  <button
                    disabled={isLoading || rows?.length < 2}
                    onClick={() => handleSubmit(rows)}
                    type="button"
                    className="grd-btn rounded-pill py-1"
                  >
                    {conditionalExp(isLoading, <Loader />, "Submit")}
                  </button>
                </div>
                <div className="">
                  <button
                    disabled={
                      rows?.reduce(
                        (a, b) => a + parseFloat(b?.volume_cover),
                        0
                      ) >= 100
                    }
                    onClick={() => {
                      setRows((pre) => [
                        ...pre,
                        {
                          item_code: null,
                          buying_qty: 0,
                          unit_cost: 0,
                          logistics_unit_cost: 0,
                          tax_per_unit: 0,
                          final_unit_cost: 0,
                          volume_cover: 0,
                          total_buying_cost: 0,
                          total_tax: 0,
                          tax_code: "",
                          isChanged: false,
                          container_cost: 0,
                          unit_cost_usd:0,
                          logistics_unit_cost_usd:0,
                          total_buying_cost_usd:0,
                          tax_per_unit_usd:0,
                          total_tax_usd:0,
                          final_unit_cost_usd:0,
                          container_cost_usd:0,
                        },
                      ]);
                    }}
                    type="button"
                    className="grd-btn rounded-pill py-1"
                  >
                    + Add More SKU
                  </button>
                </div>
              </div>
            </div>
            <div className="sku-footprint">
              {conditionalExp(
                isLoadingAll,
                <Loader />,
                <table class="table text-centerl my-4">
                  <thead>
                    <tr>
                      <th>SKU</th>
                      <th>Buying&nbsp;Quantity</th>
                      <th>Unit&nbsp;Cost</th>
                      <th>Logistic&nbsp;Unit&nbsp;Cost</th>
                      <th>Tax&nbsp;Per&nbsp;Unit</th>
                      <th>Final&nbsp;Unit&nbsp;Cost</th>
                      <th>Volume&nbsp;Cover&nbsp;(%)</th>
                      <th>Volume&nbsp;Cover (CBM)</th>
                      <th style={{ borderLeft: "1px dashed black" }}>
                        Total&nbsp;Buying&nbsp;Cost
                      </th>
                      <th>Total&nbsp;Tax</th>
                      <th>Total&nbsp;Pallets</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {rows?.map((row, index) => (
                      <tr
                        key={row?.item_code + "itrems" + index}
                        style={{ overflow: "visible" }}
                      >
                        <td style={{ overflow: "visible", minWidth: "250px" }}>
                          <Select
                            required
                            value={row?.item_code}
                            onChange={(option) => {
                              setRows((pre) => {
                                let update = [...pre];
                                update[index] = {
                                  ...update[index],
                                  item_code: option,
                                  isChanged: true,
                                };
                                return [...update];
                              });
                            }}
                            options={skuListDetailsRaw
                              ?.filter((tag) =>
                                rows.every(
                                  (item) =>
                                    item?.item_code?.value != tag?.item_code
                                )
                              )
                              ?.map((tag) => ({
                                label: `${tag?.item_code} | ${tag?.item_name}`,
                                value: tag?.item_code,
                              }))}
                            isSearchable={true}
                            placeholder="Choose SKU"
                            blurInputOnSelect={true}
                            isLoading={!skuListDetailsRaw?.length}
                          />
                          <i>
                            {row?.tax_code} Pickup: {row?.pickup_address}{" "}
                            Delivery: {row?.delivery_address}
                          </i>
                        </td>
                        <td>
                          <div>
                            <input
                              style={{ minWidth: "95px" }}
                              type="number"
                              className="form-control"
                              placeholder="Enter Quantity"
                              value={row?.buying_qty || ""}
                              onChange={(e) => {
                                setRows((pre) => {
                                  let update = [...pre];
                                  update[index] = {
                                    ...update[index],
                                    buying_qty: e.target.value,
                                    isChanged: true,
                                  };
                                  return [...update];
                                });
                              }}
                            />
                          </div>
                        </td>
                        <td>
                          <div>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Unit Cost"
                              value={row?.unit_cost}
                              disabled
                            />
                          </div>
                        </td>
                        <td>
                          <div>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Logistic Unit Cost"
                              value={row?.logistics_unit_cost}
                              disabled
                            />
                          </div>
                        </td>
                        <td>
                          <div>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Tax Per Unit"
                              value={row?.tax_per_unit}
                              disabled
                            />
                          </div>
                        </td>
                        <td>
                          <div>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Final Unit Cost"
                              value={row?.final_unit_cost}
                              disabled
                            />
                          </div>
                        </td>
                        <td>
                          <div>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Volume Cover "
                              value={row?.volume_cover}
                              disabled
                            />
                          </div>
                        </td>
                        <td>
                          <div>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Volume Cover (CBM)"
                              value={row?.volum_used_cmb}
                              disabled
                            />
                          </div>
                        </td>
                        <td style={{ borderLeft: "1px dashed black" }}>
                          <div>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Total Buying Cost"
                              value={row?.total_buying_cost}
                              disabled
                            />
                          </div>
                        </td>
                        <td>
                          <div>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Total Tax"
                              value={row?.total_tax}
                              disabled
                            />
                          </div>
                        </td>
                        <td>
                          <div>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Total Pallets"
                              value={row?.total_required_pallet}
                              disabled
                            />
                          </div>
                        </td>
                        <td>
                          <div className="d-flex ">
                            {row?.isChanged && (
                              <button
                                disabled={isLoading}
                                type="button"
                                className=" grd-btn"
                                onClick={() =>
                                  mtm_user_data?.demand_forcast_type
                                    ? handleSubmit([row], index)
                                    : handleSubmit(row, index)
                                }
                              >
                                {isLoading ? <Loader /> : "Submit"}
                              </button>
                            )}
                            {index != 0 && (
                              <button
                                type="button"
                                className="p-0 mx-2 border-0"
                                style={{ background: "transparent" }}
                                onClick={() => {
                                  let removed = rows;
                                  removed.splice(index, 1);
                                  setRows([...removed]);
                                  console.log(rows);
                                }}
                              >
                                <svg className="icon fs-22">
                                  <use href="#icon_minus"></use>
                                </svg>
                              </button>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </Col>
        </Row>
      </Container>

      <ErrorModal
        error={error}
        setShowErrModal={setShowErrModal}
        showErrModal={showErrModal}
      />
    </>
  );
}

export default SkuFootprint;
