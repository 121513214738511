import React, { useCallback, useEffect, useRef, useState } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { conditionalExp, getDemandFactor } from "../utils/commonFn";
import axios from "axios";
import Loader from "../components/Loader";
import ErrorModal from "../components/ErrorModal";
import { SuccessModal } from "../components/SuccessModal";
import { CustomIcon } from "../components/Common";
import { DatePicker } from "antd";
import dayjs from "dayjs";
function DemandForcast() {
  const mtm_user_data = JSON.parse(localStorage.getItem("mtm_user_data"));

  const [showUpload, setShowUpload] = useState(false);
  const [previewUrl, setpreviewUrl] = useState();
  const [isFileUploading, setisFileUploading] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [rawFile, setrawFile] = useState();
  const [rows, setRows] = useState([]);
  const [showErrModal, setShowErrModal] = useState(false);
  const [error, setError] = useState();
  const MAX_FILE_SIZE = 6 * 1024 * 1024; // 6MB in bytes
  const [fileUploadLimitRxError, setfileUploadLimitRxError] = useState(false);
  const [showSuccess, setshowSuccess] = useState(false);
  const [successMessagePopup, setSuccessMessagePopup] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, sethasMore] = useState(false);
  const [bulkUploadYear, setBulkUploadYear] = useState(dayjs());
  const [demandFactor, setDemandFactor] = useState();
  const observer = useRef();
  const tempRef = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      console.log("intersecting");
      if (isloading) {
        return;
      }
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setCurrentPage((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isloading, hasMore]
  );
  const handleShowUpload = () => {
    setpreviewUrl(null);
    setrawFile(null);
    setShowUpload(true);
    setBulkUploadYear();
  };

  const handleCloseUpload = () => {
    setShowUpload(false);
    setpreviewUrl(null);
    setrawFile(null);
    setBulkUploadYear();
  };
  const handleChangeFileFun = (e) => {
    for (let file of e.target.files) {
      if (file && file.size > MAX_FILE_SIZE) {
        setfileUploadLimitRxError(true);
        return;
      }
    }
    setfileUploadLimitRxError(false);
    setrawFile(Object.values(e.target.files));
  };
  const checkRxType = (file) => {
    let rawFilee = file ? file : rawFile;
    let objectURL = URL.createObjectURL(rawFilee);
    if (!rawFilee) {
      return (
        <img
          src={objectURL}
          style={{
            width: "40px",
            height: "30px",
            margin: "5px",
            marginRight: "10px",
          }}
          alt="doc"
        />
      );
    }
    let type = rawFilee?.name?.split(".");
    return getTypeDoc(objectURL, type);
  };
  const getTypeDoc = (path, typ) => {
    if (!path) {
      return "";
    }
    let type = typ ? typ : path?.split(".");
    if (type[type.length - 1] == "pdf") {
      return returnImgComp(window.location.origin + "/images/pdf.png");
    } else if (
      type[type.length - 1] == "xls" ||
      type[type.length - 1] == "xlsx"
    ) {
      return returnImgComp(window.location.origin + "/images/exel.png");
    } else if (
      type[type.length - 1] == "doc" ||
      type[type.length - 1] == "docx"
    ) {
      return returnImgComp(window.location.origin + "/images/doc.png");
    }

    return returnImgComp(path);

    function returnImgComp(src) {
      return (
        <img
          src={src}
          style={{
            width: "30px",
            height: "30px",
            margin: "5px",
            marginRight: "10px",
          }}
          alt="doc"
        />
      );
    }
  };
  function handleCloseSuccess() {
    setshowSuccess(false);
    setSuccessMessagePopup("");
  }
  async function handleFileUpload(event) {
    event.preventDefault();
    if (!rawFile?.length) {
      setError({ title: "Error", err: "Please select a file to upload" });
      setShowErrModal(true);
      return;
    } else if (!bulkUploadYear) {
      setError({ title: "Error", err: "Please select year to upload" });
      setShowErrModal(true);
      return;
    }
    setisFileUploading(true);
    try {
      const token = localStorage.getItem("mtm_user_token");
      const formData = new FormData();
      formData.append(
        "customer_id",
        JSON.parse(localStorage.getItem("mtm_user_data"))?.customer_id
      );
      rawFile?.forEach((file) => {
        formData.append("demandforcast", file);
      });

      formData.append(
        "demand_forcast_type",
        mtm_user_data?.demand_forcast_type
      );
      formData.append("year", bulkUploadYear?.format("YYYY"));

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmdemandforcast/upload_demandforcast`,
        method: "POST",
        headers: {
          jwt_token: token,
        },

        data: formData,
      });

      if (!response?.data?.error) {
        setshowSuccess(true);
        setSuccessMessagePopup(response.data?.message);
        handleCloseUpload();
        // setErrRows(response?.data?.errorItems || []);
        setTimeout(() => {
          !response?.data?.errorItems?.length && handleCloseSuccess();
        }, 5000);
      } else {
        setError({ title: "Error", err: response?.data?.message });
        setShowErrModal(true);
      }
    } catch (error) {
      setShowErrModal(true);
      setError({ title: "Error", err: error?.message });
      if (error?.response?.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }

      if (error?.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setisFileUploading(false);
      getSkuList("reload");
    }
  }
  const navigate = useNavigate();

  async function getSkuList(from) {
    try {
      setIsloading(true);
      let token = localStorage.getItem("mtm_user_token");
      if (from == "reload") {
        setCurrentPage(0);
      }
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmdemandforcast/demandforcast_list`,
        method: "POST",
        data: {
          customer_id: mtm_user_data?.customer_id,
          page: from == "reload" ? 0 : currentPage,
          searchtxt: searchInput,
          year: demandFactor ? demandFactor?.format("YYYY") : null,
          demand_forcast_type: mtm_user_data?.demand_forcast_type,
          demand_factor: getDemandFactor(demandFactor, mtm_user_data),
        },
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
      });

      setIsloading(false);
      sethasMore(false);

      if (!response.data.error) {
        const newList = response.data.result.filter((newList) => {
          return !rows.some((existingList) => existingList.id === newList.id);
        });
        if (from == "reload") {
          setRows(response.data.result);
        } else {
          setRows([...rows, ...newList]);
        }
        sethasMore(newList.length > 0);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
      setCurrentPage(0);
    } finally {
      setIsloading(false);
    }
  }

  useEffect(() => {
    if (currentPage == 0) {
      getSkuList("reload");
    } else {
      getSkuList();
    }
  }, [currentPage]);

  useEffect(() => {
    if (!searchInput.length || demandFactor) {
      getSkuList("reload");
    }
  }, [searchInput, demandFactor]);

  useEffect(() => {
    if (!rawFile || rawFile.length == 0) {
      setpreviewUrl(null);
      return;
    }
    const objectURL = URL.createObjectURL(
      rawFile.length ? rawFile[0] : rawFile
    );
    setpreviewUrl(objectURL);
  }, [rawFile]);

  return (
    <>
      <Container style={{ minWidth: "100%" }}>
        <Row>
          <Col>
            <div className="wrapper-box">
              <h2 className="fw-700 fs-30 mb-3 text-center">Demand Forecast</h2>

              <div className="d-flex justify-content-between align-items-end">
                <div className="d-flex ">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      getSkuList("reload");
                    }}
                    className="d-flex mt-2"
                  >
                    <input
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                      type="search"
                      className="form-control rounded-pill  d-block"
                      style={{ width: "400px", alignSelf: "start" }}
                      placeholder="Search Art Code or Name"
                      id="datatable-search-input"
                    />
                    <button
                      type="submit"
                      className="grd-btn mx-2"
                      disabled={!searchInput}
                    >
                      <CustomIcon name="icon_search" className="fs-5" />
                    </button>
                  </form>
                  <DatePicker
                    popupClassName="datepicker"
                    value={demandFactor}
                    onChange={setDemandFactor}
                    picker={conditionalExp(
                      mtm_user_data?.demand_forcast_type,
                      mtm_user_data?.demand_forcast_type?.replace("ly", ""),
                      "weekly"
                    )}
                    className="ms-3 mt-2"
                  />
                </div>

                <div className="me-4 d-flex">
                  <button
                    onClick={handleShowUpload}
                    type="button"
                    className="grd-btn rounded-pill py-1"
                  >
                    Bulk Upload
                  </button>
                </div>
              </div>
            </div>
            {demandFactor && (
              <div className="d-flex mt-3">
                <b>Total Qty</b>:{" "}
                {rows?.reduce((a, b) => a + parseFloat(b.demand_qty), 0)} ,
                <b>Total CBM</b> :{" "}
                {rows?.reduce(
                  (a, b) => a + parseFloat(b.demand_qty * b.unit_cbm),
                  0
                ).toFixed(5)}
              </div>
            )}
            <div
              style={{
                overflowX: "auto",
              }}
              className=" normal-tbl"
            >
              <table id="skuListTable" class="text-center my-4">
                <thead>
                  <tr>
                    <th>Item Code</th>
                    {/* <th>Item Group Code</th> */}
                    <th>Item Name</th>
                    <th>Demand Qty</th>
                    <th>
                      {mtm_user_data?.demand_forcast_type?.replace("ly", "")}
                    </th>
                    <th>Demand Type</th>
                    <th>Demand Year</th>
                    <th>Origin</th>
                    <th>Unit CBM</th>
                    <th>Units Per Pallet</th>
                    <th>Warehouse City</th>
                    <th>Warehouse Code</th>
                    <th>Warehouse Type</th>
                  </tr>
                </thead>
                <tbody>
                  {rows?.map((row, index) => (
                    <tr
                      ref={
                        rows?.length == index + 1 ? lastBookElementRef : tempRef
                      }
                      key={row?.item_code + "itrems" + index}
                      style={{ overflow: "visible" }}
                    >
                      <td>{row.item_code}</td>
                      {/* <td>{row.items_group_code}</td> */}
                      <td>{row.item_name}</td>
                      <td>{row.demand_qty}</td>
                      <td>{row.demand_factor}</td>
                      <td>{row.demand_type}</td>
                      <td>{row.demand_year}</td>
                      <td>{row.origin}</td>
                      <td>{row.unit_cbm}</td>
                      <td>{row.units_per_pallet}</td>
                      <td>{row.warehouse_city}</td>
                      <td>{row.warehouse_code}</td>
                      <td>{row.warehouse_type}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {conditionalExp(
                isloading == false && rows.length == 0,
                <p className="w-full  text-center">No SKU found</p>
              )}
              {conditionalExp(
                isloading,
                <div className="w-full text-center">
                  <Loader />
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      <Modal
        show={showUpload}
        centered
        onHide={handleCloseUpload}
        className="tracking-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5">
          <h4 className="text-center pb-3 fs-22">
            Bulk Upload Demand Forecast
          </h4>
          <p className="text-center pb-3">
            <b>Note:</b> Only excel files are accepted. <br />
            <a
              className="text-center mb-3 "
              href={`demand forcast ${
                mtm_user_data?.demand_forcast_type || "weekly"
              }.xlsx`}
              download
            >
              Download Sample File
            </a>
          </p>

          <div>
            <DatePicker
              disabledDate={(current) =>
                current && current.year() < dayjs().year()
              }
              popupClassName="datepicker"
              value={bulkUploadYear}
              onChange={setBulkUploadYear}
              picker="year"
              className="w-100 mb-3"
            />
          </div>

          <div className="upload-box p-3">
            <label className="drag-box input-file">
              <img
                className="img-fluid rounded-start"
                width={80}
                height={55}
                src={window.location.origin + "/images/uploader.png"}
                alt="..."
              />
              <input
                type="file"
                accept=".xls,.xlsx"
                id="fileUpload2"
                onChange={handleChangeFileFun}
              />
              <span>
                Drag and drop your image or <a href="">browse file</a> on your
                computer
              </span>
            </label>
          </div>
          <div className="d-block rounded-2 p-1 my-3">
            {previewUrl ? (
              rawFile?.map((file, index) => (
                <div
                  key={file?.name + "rawFiles"}
                  className="file-import d-flex ai-center my-2"
                  id="remove_${inputid}"
                >
                  {checkRxType(file)}
                  <span style={{ marginRight: "auto" }}>{file?.name}</span>

                  <button
                    onClick={() => {
                      setrawFile((pre) =>
                        pre.filter((item, iIndex) => index != iIndex)
                      );
                    }}
                  >
                    <svg
                      className="icon"
                      style={{ width: "20px", height: "20px" }}
                    >
                      <use href="#icon_fileclose"></use>
                    </svg>
                  </button>
                </div>
              ))
            ) : (
              <div></div>
            )}
          </div>
          {fileUploadLimitRxError && (
            <div className="mb-2 w-100 text-danger text-center">
              Please upload file less than 6MB
            </div>
          )}
          <div className="mt-1">
            <button
              disabled={isFileUploading}
              onClick={handleFileUpload}
              className="button button-blue upload-btn w-100 py-2 fs-20"
            >
              {conditionalExp(isFileUploading, <Loader />, "Upload")}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <ErrorModal
        error={error}
        setShowErrModal={setShowErrModal}
        showErrModal={showErrModal}
      />
      <SuccessModal
        showSuccess={showSuccess}
        successMessagePopup={successMessagePopup}
        handleCloseSuccess={handleCloseSuccess}
      />
    </>
  );
}

export default DemandForcast;
