import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const colors = ['#66C9D7'];

function TypeChart({containerTypeList}) {
  const [chartData,setChartData] = useState({
    series: [
      {
        data: [],
      },
    ],
    options: {
      chart: {
        type: 'bar',
        events: {
          click: function (chart, w, e) {
            // console.log(chart, w, e)
          },
        },
      },
      colors: colors,
      plotOptions: {
        bar: {
          columnWidth: '45%',
          distributed: true,
        },
      },
      dataLabels: {
        enabled: true,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: [
        ],
        labels: {
          style: {
            // colors: colors,
            fontSize: '12px',
          },
        },
      },
    },
  });
  useEffect(() => {
    
    const series = containerTypeList?.map(item=> {
      const percentage =
      item?.total_container
    return {
      container_type: item?.container_type,
      percentage: percentage,
    }
    })

    setChartData(pre => ({...pre,series:[{data:series.map(item=>item?.percentage)}],options:{...pre.options,xaxis:{...pre.options.xaxis,categories:series.map(item=>item?.container_type )}}}));
    
  
  }, [containerTypeList])
  
  return (
    <div id="chart">
      {containerTypeList?.length ? <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={280} /> :  <div >No Container Type Data Found For Selected Date</div>}
    </div>
  );
}

export default TypeChart;
